import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import { Checkbox } from "components/ui/Checkbox";

interface IProps {
	onChange: (immediateRevoke: boolean) => void;
	value: boolean;
	disabled?: boolean;
}

export const ImmediateRevokeCheckbox: FC<IProps> = ({ onChange, value, disabled }) => {
	const { t } = useTranslation();

	const onClicked = useCallback(() => {
		!disabled && onChange(!value);
	}, [disabled, onChange, value]);

	return (
		<Checkbox
			selected={value}
			onClick={onClicked}
			disabled={disabled}
			label={t("common.immediateRevoke.label")}
			description={t(`common.immediateRevoke.description.${disabled ? "disabled" : "enabled"}`)}
		/>
	);
};
