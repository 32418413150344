import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EntityNode, type TEntityNodeColor } from "components/common/Nodes/EntityNode";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { GroupTooltipContent, TStatus } from "./GroupTooltipContent";
import { useStyles } from "./styles";
import type { List } from "immutable";

interface IProps {
	status: TStatus;
	responderIds?: List<string>;
	approverIds: List<string>;
	type: "IntegrationOwner" | "ResourceOwner";
}

const DEFAULT_COLOR = "magenta" as TEntityNodeColor;
const COLOR_BY_STATUS = new Map<TStatus, TEntityNodeColor>([
	["approved", "green"],
	["declined", "red"],
	["pending", "magenta"]
]);

export const OwnerCard: FC<IProps> = ({ status, approverIds, responderIds, type }) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "common.requestDetails.approvalProcess.step.ownerCard" });

	const text = t(type);
	const icon = useMemo(() => <OwnerIcon className={classes.icon} />, [classes.icon]);
	const groupColor = COLOR_BY_STATUS.get(status);

	return (
		<GroupTooltipContent approverIds={approverIds} responderIds={responderIds} status={status} title={text}>
			<EntityNode
				content={text}
				icon={icon}
				className={classes.cardContainer}
				selected={status !== "pending"}
				color={groupColor || DEFAULT_COLOR}
			/>
		</GroupTooltipContent>
	);
};
