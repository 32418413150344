import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { compiledClientConfig } from "config";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import type { TOAuthError } from "hooks/useChangeConfig";

type TAuthorizeFunction = (configuration: Record<string, unknown>) => void;
type TErrorFunction = (error: string, code?: number) => void;
type TOAuthErrorResponse = { error: TOAuthError };

const MAX_RETRY_COUNT = 120;
const RETRY_INTERVAL_MS = 1000;
const POPUP_SIZE_PIXELS = 500;

interface IProps {
	adapterName: string;
	onAuthorize: TAuthorizeFunction;
	onError: TErrorFunction;
	valid: boolean;
	validating: boolean;
}

const getOrigin = (url: string) => {
	const urlObject = new URL(url);
	return `${urlObject.protocol}//${urlObject.host}`;
};

const isErrorResponse = (response: Record<string, unknown>): response is TOAuthErrorResponse =>
	Boolean(
		response.error &&
			!isNaN(Number(response.error)) &&
			typeof (response as TOAuthErrorResponse).error.message === "string"
	);

const handleAuthorizationFromWindow = (
	authorizeWindow: Window,
	onAuthorize: TAuthorizeFunction,
	onError: TErrorFunction
) => {
	const authorizeOrigin = getOrigin(compiledClientConfig.integrationOAuthUrl);
	let retryTimeout: NodeJS.Timeout | null = null;
	const handleAuthMessage = (event: MessageEvent) => {
		const eventOrigin = getOrigin(event.origin);

		if (authorizeOrigin !== eventOrigin) {
			return;
		}

		const { data } = event;
		try {
			const parsedResponse: Record<string, unknown> | { error: { code: number; message: string } } = JSON.parse(data);
			if (isErrorResponse(parsedResponse)) {
				onError(parsedResponse.error.message, parsedResponse.error.code);
			}
			onAuthorize(parsedResponse);
		} catch (error) {
			onError("Couldn't parse authorization response");
		}
		authorizeWindow.close();
		window.removeEventListener("message", handleAuthMessage);
		retryTimeout && clearTimeout(retryTimeout);
	};

	window.addEventListener("message", handleAuthMessage);

	let retryCount = 0;

	const requestConfiguration = () => {
		retryCount++;
		authorizeWindow.postMessage("getConfiguration", authorizeOrigin);
	};

	const retry = () => {
		if (!authorizeWindow || authorizeWindow.closed || retryCount > MAX_RETRY_COUNT) {
			onError("Authorization failed");
			return;
		}
		requestConfiguration();
		retryTimeout = setTimeout(retry, RETRY_INTERVAL_MS);
	};
	retry();
};

export const AuthorizeWithOAuth: FC<IProps> = ({
	adapterName,
	className,
	innerRef,
	onAuthorize,
	onError,
	valid,
	validating
}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const handleAuthorize = useCallback(
		(configuration: Record<string, unknown>) => {
			onAuthorize(configuration);
			setLoading(false);
		},
		[onAuthorize]
	);

	const handleError = useCallback(
		(error: string, code?: number) => {
			onError(error, code);
			setLoading(false);
		},
		[onError]
	);

	const getOptions = useCallback(() => {
		const top = (window.screen.height - POPUP_SIZE_PIXELS) / 2;
		const left = (window.screen.width - POPUP_SIZE_PIXELS) / 2;
		return `toolbar=no,resizable=yes,top=${top},left=${left},width=${POPUP_SIZE_PIXELS},height=${POPUP_SIZE_PIXELS}`;
	}, []);

	const onClick = useCallback(() => {
		if (valid) return;
		setLoading(true);
		const url = `${compiledClientConfig.integrationOAuthUrl}${adapterName}`;
		const authorizeWindow = window.open(url, "_blank", getOptions());
		if (!authorizeWindow) {
			handleError("Cannot open window");
			return;
		}
		authorizeWindow.focus();
		handleAuthorizationFromWindow(authorizeWindow, handleAuthorize, handleError);
	}, [adapterName, handleAuthorize, handleError, getOptions, valid]);

	const suffix = useMemo(() => {
		if (valid) {
			return <GrantedIcon />;
		}
		if (validating) {
			return <LoadingSpinner inline />;
		}
		return undefined;
	}, [valid, validating]);

	return (
		<Button
			variant="secondary"
			innerRef={innerRef}
			className={className}
			loading={loading}
			onClick={onClick}
			disabled={validating}
			suffix={suffix}>
			{t("common.authorizeWithOAuth.label")}
		</Button>
	);
};
