import { OktaLogo } from "components/ui/systemLogos/OktaLogo";
import { GoogleLogo } from "components/ui/systemLogos/GoogleLogo";
import { JumpCloudLogo } from "components/ui/systemLogos/JumpCloudLogo";
import { OneLoginLogo } from "components/ui/systemLogos/OneLoginLogo";
import { EntraIDLogo } from "components/ui/systemLogos/EntraIDLogo";
import { LdapLogo } from "components/ui/systemLogos/LdapLogo";

export const sourceIcons = {
	okta: OktaLogo,
	google: GoogleLogo,
	azureAD: EntraIDLogo,
	jumpcloud: JumpCloudLogo,
	ldap: LdapLogo,
	onelogin: OneLoginLogo
};

export type TSources = keyof typeof sourceIcons;
