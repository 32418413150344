import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	resourceOption: {
		display: "flex",
		gap: "1rem",
		"& > $resourceOptionData": {
			display: "flex",
			flexDirection: "column"
		},
		"& > $image": {
			border: "var(--surface-block-border)",
			borderRadius: "var(--border-radius-semi-rounded)",
			height: "2.5rem",
			width: "2.5rem"
		}
	},
	image: {},
	resourceOptionData: {}
});
