import React, { useCallback, useEffect, useState } from "react";
import { Button } from "components/ui/Button";
import { Modal } from "components/ui/Modal";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useTranslation } from "react-i18next";
import { IntegrationSelectInput } from "components/common/IntegrationSelectInput";
import { useIntegrations } from "hooks/useIntegrations";
import { ResourceSelectInput } from "components/common/ResourceSelectInput";
import { RoleSelectInput } from "components/common/RoleSelectInput";
import classNames from "classnames";
import { TitleBody } from "components/ui/TitleBody";
import { useStyles } from "./styles";

interface IProps {
	addResourcePermission: (roleId: string, resourceId: string) => Promise<void>;
	originResourceId: string;
	isLoading?: boolean;
	isOpen?: boolean;
	onClose: () => void;
	state: "to" | "from";
}

export const AddResourcePermissionModal: FC<IProps> = ({
	addResourcePermission,
	originResourceId,
	isLoading,
	isOpen = false,
	onClose,
	state
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [integration, setIntegration] = useState<IntegrationModel | null>(null);
	const [integrationResource, setIntegrationResource] = useState<IntegrationResourceModel | null>(null);
	const [integrationResourceRole, setIntegrationResourceRole] = useState<IntegrationResourceRoleModel | null>(null);
	const integrations = useIntegrations();

	const resetForm = useCallback(() => {
		setIntegration(null);
		setIntegrationResource(null);
		setIntegrationResourceRole(null);
	}, []);

	const onSubmit = useCallback(async () => {
		if (!integrationResourceRole || !integrationResource) return;
		await addResourcePermission(integrationResourceRole.id, state === "to" ? originResourceId : integrationResource.id);
		onClose();
		resetForm();
	}, [
		integrationResourceRole,
		integrationResource,
		addResourcePermission,
		state,
		originResourceId,
		onClose,
		resetForm
	]);

	const onChangeIntegration = useCallback(
		(newIntegration: IntegrationModel | null) => {
			setIntegration(newIntegration);
			setIntegrationResource(null);
			if (state === "to") setIntegrationResourceRole(null);
		},
		[state]
	);

	const onChangeResource = useCallback(
		(newResource: IntegrationResourceModel | null) => {
			setIntegrationResource(newResource);
			if (state === "to") setIntegrationResourceRole(null);
		},
		[state]
	);

	useEffect(() => {
		resetForm();
	}, [state, resetForm]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			actions={
				<Button onClick={onSubmit} loading={isLoading}>
					{t("buttons.save")}
				</Button>
			}
			content={
				<div className={classes.modalContent}>
					<TitleBody size="large" title={t("pages.integrationResource.addResourcePermissionTitle")} />
					<div className={classes.resourcePermissionForm}>
						<IntegrationSelectInput
							className={classes.input}
							label={t("common.integrationSelectInput.label")}
							onChange={onChangeIntegration}
							options={integrations}
							placeholder={t("common.integrationSelectInput.placeholder")}
							required
							value={integration}
						/>
						<ResourceSelectInput
							className={classes.input}
							inputLabel={t("common.resourceSelectInput.label")}
							onChange={onChangeResource}
							placeholder={t("common.resourceSelectInput.placeholder")}
							required
							selected={integrationResource}
							selectedIntegrationId={integration?.id || ""}
						/>
						<RoleSelectInput
							className={classNames(classes.input, { [classes.prioritize]: state === "from" })}
							disabled={state === "to" && (!integration || !integrationResource)}
							inputLabel={t("common.roleSelectInput.label")}
							integrationResourceId={state === "to" ? integrationResource?.id : originResourceId}
							onChange={setIntegrationResourceRole}
							placeholder={t("common.roleSelectInput.placeholder")}
							required
							selectedRole={integrationResourceRole}
						/>
					</div>
				</div>
			}
		/>
	);
};
