import { useSortTableContext } from "context/sortingTableContext";
import { useCallback } from "react";
import { DESC } from "./usePagination";

export const useSortableHeader = (title: string, field?: string) => {
	const {
		actions: { setHoveredField, setSortState },
		state: { hoveredField, sortFields, sortOrder }
	} = useSortTableContext();
	const sortVisible = hoveredField === title || sortFields[0] === field;
	const onHover = useCallback(() => setHoveredField(title), [setHoveredField, title]);
	const onLeave = useCallback(() => setHoveredField(undefined), [setHoveredField]);

	return { sortOrder: sortOrder ?? DESC, sortFields, sortVisible, onHover, onLeave, setSortState };
};
