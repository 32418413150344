import constate from "constate";
import { List, Map } from "immutable";
import { useCallback } from "react";
import { createApprovalFlow, getApprovalFlows, updateApprovalFlow as apiUpdateApprovalFlow } from "api/approvalFlow";
import { ApprovalFlowModel } from "models/ApprovalFlowModel";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useFetchedState } from "hooks/useFetchedState";

const useApprovalFlows = () => {
	const {
		data: approvalFlows,
		setData: setApprovalFlows,
		loadData: loadApprovalFlows
	} = useFetchedState(getApprovalFlows);

	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const getMap = useCallback(async (): Promise<Map<string, ApprovalFlowModel>> => {
		if (approvalFlows) return approvalFlows;
		try {
			return getApprovalFlows();
		} catch (err) {
			openGlobalErrorModal(err as Error);
			return Map();
		}
	}, [approvalFlows, openGlobalErrorModal]);

	const handleApprovalFlows = useCallback(
		async (approvalFlowsToHandle: List<ApprovalFlowModel>) => {
			const flowRequests = approvalFlowsToHandle.map(approvalFlow => {
				if (approvalFlow.id) {
					return apiUpdateApprovalFlow(approvalFlow);
				}
				return createApprovalFlow(approvalFlow);
			});

			const responseFlows = await Promise.all(flowRequests);
			const currentFlows = await getMap();
			const newApprovalFlows = responseFlows.reduce((final, flow) => final.set(flow.id, flow), currentFlows);
			setApprovalFlows(newApprovalFlows);
			return responseFlows;
		},
		[getMap, setApprovalFlows]
	);

	return {
		state: { approvalFlows },
		actions: { handleApprovalFlows, loadApprovalFlows }
	};
};

export const [ApprovalFlowsProvider, useApprovalFlowsContext] = constate(useApprovalFlows);
