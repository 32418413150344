import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	name: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem",
		width: "100%"
	},
	option: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "0.2rem",
		width: "100%"
	}
});
