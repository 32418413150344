import React from "react";
import { Button } from "components/ui/Button";
import { Modal } from "components/ui/Modal";
import { IntegrationModel } from "models/IntegrationModel";
import { useTranslation } from "react-i18next";
import { UserModel } from "models/UserModel";
import { TitleBody } from "components/ui/TitleBody";

// This modal is for when the user asks on behalf of someone else, and he has missing actors.
interface IReceiverMissingActorsModalProps {
	missingIntegrations: IntegrationModel[];
	receiverUser: UserModel | null;
	onClose: () => void;
	isOpen: boolean;
}

export const ReceiverMissingActorsModal: FC<IReceiverMissingActorsModalProps> = ({
	missingIntegrations,
	receiverUser,
	onClose,
	isOpen
}) => {
	const { t } = useTranslation();

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			actions={
				<Button size="medium" onClick={onClose}>
					{t("buttons.close")}
				</Button>
			}
			content={
				<TitleBody
					size="large"
					title={t("pages.newTicket.newTicketForm.receiverMissingActorModal.title", {
						receiverUser: receiverUser?.fullName
					})}
					body={t("pages.newTicket.newTicketForm.receiverMissingActorModal.fillInformation", {
						missingIntegrations: missingIntegrations.map(integration => integration.name),
						receiverUser: receiverUser?.fullName
					})}
				/>
			}
		/>
	);
};
