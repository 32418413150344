import { useCallback } from "react";
import { useOpenGlobalErrorModal } from "./useGlobalError";

export const useWithGlobalErrorHandler = () => {
	const handleError = useOpenGlobalErrorModal();

	return useCallback(
		async <T>(promise: Promise<T>): Promise<T | null> => {
			try {
				return promise;
			} catch (e) {
				handleError(e as Error);
				return null;
			}
		},
		[handleError]
	);
};
