import constate from "constate";
import { Map } from "immutable";
import { useCallback, useMemo } from "react";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useFetchedState } from "hooks/useFetchedState";
import {
	createPolicy as apiCreatePolicy,
	updatePolicy as apiUpdatePolicy,
	movePolicy as apiMovePolicy,
	deletePolicy as apiDeletePolicy,
	getPolicies,
	ICreatePolicyParams
} from "api/policies";
import { PolicyModel } from "models/PolicyModel";

const usePolicies = () => {
	const {
		data: policies,
		setData: setPolicies,
		loadData: loadPolicies,
		retryAction: reloadPolicies
	} = useFetchedState(getPolicies);

	const lastSortOrder = useMemo(
		() => (policies ? Math.max(1, ...policies.valueSeq().map(policy => policy.sortOrder)) : 1),
		[policies]
	);

	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const getMap = useCallback(async (): Promise<Map<string, PolicyModel>> => {
		if (policies) return policies;
		try {
			return getPolicies();
		} catch (err) {
			openGlobalErrorModal(err as Error);
			return Map();
		}
	}, [policies, openGlobalErrorModal]);

	const createPolicy = useCallback(
		async (data: ICreatePolicyParams) => {
			try {
				const policy = await apiCreatePolicy(data);
				setPolicies((await getMap()).set(policy.id, policy));

				return policy;
			} catch (err) {
				openGlobalErrorModal(err as Error);
				return;
			}
		},
		[getMap, openGlobalErrorModal, setPolicies]
	);

	const updatePolicy = useCallback(
		async (id: string, data: ICreatePolicyParams) => {
			try {
				const policy = await apiUpdatePolicy(id, data);
				setPolicies((await getMap()).set(policy.id, policy));

				return policy;
			} catch (err) {
				openGlobalErrorModal(err as Error);
				return;
			}
		},
		[setPolicies, getMap, openGlobalErrorModal]
	);

	const movePolicy = useCallback(
		async (id: string, direction: -1 | 1) => {
			try {
				const currentPolicy = policies?.get(id);
				if (currentPolicy) {
					const newSortOrder = currentPolicy.sortOrder + direction;
					if (newSortOrder === 0 || newSortOrder > lastSortOrder) return;
					await apiMovePolicy(id, direction);
					setPolicies(newPolicies => {
						const editedPolicies = newPolicies!.set(id, currentPolicy.set("sortOrder", newSortOrder));
						const nextPolicy = newPolicies!.find(policy => policy.sortOrder === newSortOrder);
						return nextPolicy
							? editedPolicies.set(nextPolicy.id, nextPolicy.set("sortOrder", currentPolicy.sortOrder))
							: editedPolicies;
					});
					return { ok: true };
				}
				return;
			} catch (err) {
				openGlobalErrorModal(err as Error);
				return;
			}
		},
		[policies, lastSortOrder, setPolicies, openGlobalErrorModal]
	);

	const deletePolicy = useCallback(
		async (policyId: string) => {
			const policiesMap = await getMap();
			const policy = policiesMap.get(policyId);
			if (policy) {
				try {
					await apiDeletePolicy(policy.id);
					await reloadPolicies();
				} catch (err) {
					openGlobalErrorModal(err as Error);
					return;
				}
			}
		},
		[reloadPolicies, getMap, openGlobalErrorModal]
	);

	return {
		state: { policies, lastSortOrder },
		actions: { loadPolicies, reloadPolicies, createPolicy, updatePolicy, deletePolicy, movePolicy }
	};
};

export const [PoliciesProvider, usePoliciesContext] = constate(usePolicies);
