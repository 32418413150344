import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	back: {
		display: "flex",
		alignItems: "center"
	},
	userInfoContainer: {
		display: "grid",
		gridTemplateColumns: "auto auto 1fr",
		marginTop: "1rem",
		gap: "1.5rem",
		"& $label": {
			fontWeight: "var(--typography-font-weight-medium)"
		},
		"& > :not(:first-child)": {
			paddingLeft: "1.5rem",
			height: "100%"
		}
	},
	userWithEmail: {
		width: "unset"
	},
	entitlementsContent: {
		padding: "1.5rem 2.25rem",
		display: "flex",
		flex: "1"
	},
	pageContent: {
		display: "flex",
		height: "1px"
	},
	label: {},
	roleContainer: {
		display: "flex",
		gap: "0.5rem",
		borderLeft: "1px solid var(--color-grey-light)"
	},
	managerContainer: {
		display: "flex",
		gap: "0.5rem",
		borderLeft: "1px solid var(--color-grey-light)"
	},
	attributesContainer: {
		display: "flex",
		gap: "0.5rem",
		padding: "1rem 2rem 0.5rem 0"
	},
	attributeChip: {
		display: "flex",
		gap: "0.5rem"
	},
	userAttributesList: {
		display: "flex",
		flexDirection: "column",
		gap: "0.1rem",
		fontSize: "0.75rem"
	}
});
