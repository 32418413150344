import genUniqueId from "lodash/uniqueId";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { ThrottleOptions, useThrottleFn } from "./useThrottle";

export const useElementDimensions = (options?: {
	subscribe: (key: string, handler: () => void) => void;
	unsubscribe: (key: string) => void;
	throttle?: { wait?: number; options?: ThrottleOptions };
}) => {
	const elementRef = useRef<HTMLDivElement | null>(null);
	const [elementWidth, setElementWidth] = useState(0);
	const [elementHeight, setElementHeight] = useState(0);
	const uniqueId = useRef(genUniqueId("useElementDimensions"));

	const getElementDimensions = useCallback(() => {
		if (elementRef.current) {
			const { width, height } = elementRef.current.getBoundingClientRect();
			setElementWidth(width);
			setElementHeight(height);
		}
	}, []);

	const [run] = useThrottleFn(getElementDimensions, options?.throttle?.wait, options?.throttle?.options);

	useLayoutEffect(() => {
		if (options?.throttle) {
			run();
		} else {
			getElementDimensions();
		}
	}, [getElementDimensions, options?.throttle, run]);

	useEffect(() => {
		const key = uniqueId.current;
		window.addEventListener("resize", getElementDimensions);
		options?.subscribe(key, getElementDimensions);
		return () => {
			window.removeEventListener("resize", getElementDimensions);
			options?.unsubscribe(key);
		};
	}, [getElementDimensions, options]);

	return { elementRef, elementWidth, elementHeight };
};
