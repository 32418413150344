import { Map } from "immutable";
import constate from "constate";
import { useCallback, useState } from "react";
import { useLoadingState } from "hooks/useLoadingState";
import { PersonalAccessTokenModel } from "models/PersonalAccessTokenModel";
import {
	createPersonalAccessToken,
	deletePersonalAccessToken,
	getPersonalAccessTokens,
	updatePersonalAccessToken
} from "api/personalAccessTokens";

const usePersonalAccessTokens = () => {
	const [personalAccessTokens, setPersonalAccessTokens] = useState<Map<string, PersonalAccessTokenModel> | null>(null);
	const { withLoader, isLoading } = useLoadingState();

	const loadTokens = useCallback(async () => {
		const loadedTokens = await withLoader(getPersonalAccessTokens());
		setPersonalAccessTokens(Map<string, PersonalAccessTokenModel>(loadedTokens.map(token => [token.id, token])));
	}, [withLoader]);

	const createToken = useCallback(async (name: string, duration: number | null) => {
		const createdToken = await createPersonalAccessToken({ name, duration });
		setPersonalAccessTokens(current => current?.set(createdToken.id, createdToken) ?? current);
	}, []);

	const editToken = useCallback(async (id: string, name: string) => {
		const updatedToken = await updatePersonalAccessToken(id, name);
		setPersonalAccessTokens(current => (current?.has(id) ? current.setIn([id, "name"], updatedToken.name) : current));
	}, []);

	const deleteToken = useCallback(async (id: string) => {
		await deletePersonalAccessToken(id);
		setPersonalAccessTokens(current => (current?.has(id) ? current.delete(id) : current));
	}, []);

	return {
		state: { personalAccessTokens, isLoading },
		actions: { loadTokens, createToken, editToken, deleteToken }
	};
};

export const [PersonalAccessTokensProvider, usePersonalAccessTokensContext] = constate(usePersonalAccessTokens);
