import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { Modal } from "components/ui/Modal";
import { createIntegrationResourceRole, TIntegrationResourceRole } from "api/integrationResourceRoles";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { TitleBody } from "components/ui/TitleBody";
import { CreateRoleForm } from "./CreateRoleForm";
import { useStyles } from "./styles";

interface IProps {
	resource: IntegrationResourceModel;
	isOpen?: boolean;
	onClose: () => void;
	onSubmit?: (role: IntegrationResourceRoleModel) => void;
}

export const CreateRoleFormModal: FC<IProps> = ({ resource, onSubmit, isOpen = false, onClose }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const resetFormRef = useRef<(() => void) | undefined>();

	const closeAndReset = useCallback(() => {
		resetFormRef.current && resetFormRef.current();
		onClose();
	}, [onClose]);

	const handleOnSubmit = useCallback(
		async (roleToUpdate: TIntegrationResourceRole) => {
			const updatedRole = await createIntegrationResourceRole(roleToUpdate);
			onSubmit && onSubmit(updatedRole);
			onClose();
		},
		[onClose, onSubmit]
	);

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeAndReset}
			content={
				<div className={classes.modalContent}>
					<TitleBody size="large" title={t("pages.integration.resource.createRole")} />
					<CreateRoleForm resource={resource} onSubmit={handleOnSubmit} resetFormRef={resetFormRef} />
				</div>
			}
		/>
	);
};
