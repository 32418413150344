import { useApprovalAlgorithmsContext } from "context/approvalAlgorithmsContext";
import { useEffect, useMemo, useState } from "react";
import { useApprovalAlgorithms } from "./useApprovalAlgorithms";

export const useApprovalAlgorithm = (id: string, withDeleted = false) => {
	const {
		actions: { loadApprovalAlgorithms }
	} = useApprovalAlgorithmsContext();
	const [loadingState, setLoadingState] = useState<"loading" | "loaded" | "error" | null>(null);
	const approvalAlgorithms = useApprovalAlgorithms(withDeleted);
	const approvalAlgorithm = useMemo(() => approvalAlgorithms?.get(id), [approvalAlgorithms, id]);
	useEffect(() => {
		(async () => {
			if (!approvalAlgorithm && !loadingState) {
				setLoadingState("loading");
				try {
					await loadApprovalAlgorithms();
					setLoadingState("loaded");
				} catch (e) {
					setLoadingState("error");
					throw e;
				}
			}
		})();
	}, [approvalAlgorithm, loadApprovalAlgorithms, loadingState]);
	return { approvalAlgorithm, loading: loadingState === "loading" || !approvalAlgorithms };
};
