import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { ErrorIcon } from "components/ui/Icons/ErrorIcon";
import { ExpiredIcon } from "components/ui/Icons/ExpiredIcon";
import { PendingIcon } from "components/ui/Icons/PendingIcon";
import { PendingApprovalIcon } from "components/ui/Icons/PendingApprovalIcon";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { CancelCircleIcon } from "components/ui/Icons/CancelCircleIcon";
import type { TFunction } from "i18next";

const TRANSLATION_PREFIX = "common.ticketStatus.";

export type TicketStatus =
	| "approved"
	| "cancelled"
	| "rejected"
	| "waitingForApproval"
	| "permissionInProgress"
	| "waitingForIT"
	| "failed"
	| "granted"
	| "revoked";

export const getApprovalIconAndText = (status: TicketStatus, t: TFunction<"translation">): [FC, string] => {
	let icon = PendingIcon;
	if (status === "approved" || status === "granted") {
		icon = DoneCircleIcon.Green;
	}
	if (status === "revoked") {
		icon = ExpiredIcon.Orange;
	}
	if (status === "rejected") {
		icon = CloseCircleIcon.Red;
	}
	if (status === "failed") {
		icon = ErrorIcon.Red;
	}
	if (status === "cancelled") {
		icon = CancelCircleIcon.Red;
	}
	if (status === "waitingForIT") {
		icon = PendingApprovalIcon;
	}
	return [icon, t(`${TRANSLATION_PREFIX}${status}`)];
};
