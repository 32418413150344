import classNames from "classnames";
import React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import { useStyles } from "./styles";

interface IProps {
	noDecoration?: boolean;
}

export const Link: FC<LinkProps & IProps> = ({ className, noDecoration, children, innerRef, ...linkProps }) => {
	const classes = useStyles();
	const isExternal = linkProps.to && typeof linkProps.to === "string" && linkProps.to.startsWith("http");
	const props = {
		className: classNames(className, {
			[classes.noDecoration]: noDecoration
		}),
		...linkProps
	};
	return isExternal ? (
		<a href={linkProps.to.toString()} ref={innerRef} {...props}>
			{children}
		</a>
	) : (
		<RouterLink ref={innerRef} {...props}>
			{children}
		</RouterLink>
	);
};
