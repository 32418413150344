import { compiledClientConfig } from "config";
import { getI18nTextIfExists } from "utils/i18n";
import { EntitleError } from "./entitleError";

interface IIsNullErrorParams {
	location?: string;
	parentObject?: {
		name: string;
		value: unknown;
	};
	requestedProperty?: string | number | symbol;
	message?: never;
	title?: never;
}

interface ICommonErrorParams {
	message: string;
	title?: string;
	location?: never;
	parentObject?: never;
	requestedProperty?: never;
}

type TIsNullErrorParams = IIsNullErrorParams | ICommonErrorParams;

const isCommonParams = (params: TIsNullErrorParams): params is ICommonErrorParams =>
	typeof params === "object" && "message" in params;

export class IsNullError extends EntitleError {
	constructor(message?: string, title?: string) {
		super(message, title || undefined);
		this.name = "IsNullError";
	}

	static from(params: TIsNullErrorParams) {
		if (isCommonParams(params)) {
			return new IsNullError(params.message, params.title);
		}
		if (compiledClientConfig.appEnv !== "production") {
			const errorMessageTexts = ["Error: "];
			params.location && errorMessageTexts.push(`in: ${params.location}`);
			if (params.parentObject) {
				errorMessageTexts.push(`of: ${params.parentObject.name}`);
				try {
					errorMessageTexts.push(`with value: ${JSON.stringify(params.parentObject.value, null, 2)}`);
				} catch {
					errorMessageTexts.push("with complex value");
				}
			}
			params.requestedProperty && errorMessageTexts.push(`property: ${params.requestedProperty.toString()}`);
			return new IsNullError(errorMessageTexts.join("\n"), "Property is null error");
		} else {
			return new IsNullError(
				getI18nTextIfExists("errors.isNull.message") || "",
				getI18nTextIfExists("errors.isNull.title") || undefined
			);
		}
	}
}
