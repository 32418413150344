import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/routes";
import { useIsBeta } from "context/betaContext";

export const Homepage: React.FC = () => {
	const navigate = useNavigate();
	const isBeta = useIsBeta();

	useEffect(() => {
		navigate(isBeta ? routes.requests.main : routes.myRequests.main);
	}, [isBeta, navigate]);

	return null;
};
