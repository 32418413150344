import { AccountVertexModel } from "./AccountVertexModel";
import { IntegrationVertexModel } from "./IntegrationVertexModel";
import { RoleVertexModel } from "./RoleVertexModel";
import { UserVertexModel } from "./UserVertexModel";

export type TVertexType = "account" | "integration" | "role" | "user";

type TVertexEntityUser = { userId: string };
export type TVertexEntityRole = {
	roleId: string;
	resourceId: string;
	integrationId: string;
	roleName: string;
	resourceName: string;
	resourceType: string;
};
type TVertexEntityAccount = {
	accountId: string;
	integrationId: string;
	accountName: string;
};
type TVertexEntityIntegration = {
	integrationId: string;
	integrationName: string;
	resourcesCount: number;
};

type TVertexEntity = TVertexEntityUser | TVertexEntityRole | TVertexEntityAccount | TVertexEntityIntegration;

export abstract class VertexModel {
	declare id: string;
	declare type: TVertexType;
	declare entity: TVertexEntity;
	declare step: number;

	static fromServerData(data: unknown): VertexModel {
		const { type } = data as { type: TVertexType };
		switch (type) {
			case "account":
				return AccountVertexModel.fromServerData(data);
			case "integration":
				return IntegrationVertexModel.fromServerData(data);
			case "role":
				return RoleVertexModel.fromServerData(data);
			case "user":
				return UserVertexModel.fromServerData(data);
			default:
				throw new Error(`Unknown vertex type: ${type}`);
		}
	}
}
