import { JiraLogo } from "components/ui/systemLogos/JiraLogo";
import { JiraServiceManagementLogo } from "components/ui/systemLogos/JiraServiceManagementLogo";
import { ZendeskLogo } from "components/ui/systemLogos/ZendeskLogo";
import type { TTicketingIntegrationType } from "models/TicketingIntegrationTicketModel";

const TICKETING_INTEGRATION_LOGOS = new Map<TTicketingIntegrationType, FC>([
	["jira", JiraLogo],
	["jira-service-management", JiraServiceManagementLogo],
	["zendesk", ZendeskLogo]
]);

export const getTicketingIntegrationLogoByType = (type: TTicketingIntegrationType) =>
	TICKETING_INTEGRATION_LOGOS.get(type);
