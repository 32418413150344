import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Button } from "components/ui/Button";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { IntegrationConfigurationInput } from "../IntegrationConfigurationInput";
import { useStyles } from "./styles";
import type { IChangeConfig } from "hooks/useChangeConfig";

export const ConfigurationSchemaInput = ({ changeConfig }: { changeConfig: IChangeConfig }) => {
	const {
		adapterless,
		checkConfig,
		config,
		configErrors,
		configState,
		isConfigLoading,
		onConfigChange,
		onError,
		validConfig,
		validValue
	} = changeConfig;
	const { t } = useTranslation();
	const classes = useStyles();
	if (!configState || configState.type !== "schema") return null;
	return (
		<IntegrationConfigurationInput
			className={classNames(classes.formArea)}
			configuration={configState.value}
			disabled={adapterless}
			errors={configErrors}
			onChange={onConfigChange}
			onError={onError}
			value={config}
			actions={
				<Button
					disabled={isConfigLoading || validConfig === "valid" || !validValue}
					loading={isConfigLoading}
					onClick={checkConfig}
					size={"small"}
					suffix={validConfig === "valid" ? <GrantedIcon /> : undefined}
					variant="text">
					{validConfig === "valid" ? t("pages.integration.validConfig") : t("pages.integration.checkConfig")}
				</Button>
			}
		/>
	);
};
