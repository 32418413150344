import { Tooltip } from "components/ui/Tooltip";
import { UserModel } from "models/UserModel";
import React, { useMemo } from "react";
import { UserWithEmail } from "../UserWithEmail";

interface IProps {
	user?: UserModel;
}

export const UserTooltip: FC<IProps> = ({ children, user }) => {
	const componentToWrap = useMemo(() => {
		return !React.isValidElement(children) ? <span>{children}</span> : children;
	}, [children]);

	const content = user ? <UserWithEmail user={user} /> : undefined;
	return <Tooltip content={content}>{componentToWrap}</Tooltip>;
};
