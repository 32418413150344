import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	comment: {
		"& > $itemBody > $itemTitle": {
			minHeight: "2rem",
			paddingBottom: "var(--spacing-x1, 4px)"
		},
		transition: "background-color 0.2s ease-in-out",
		willChange: "background-color"
	},
	editing: {
		backgroundColor: "var(--color-purple-5)"
	},
	itemBody: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		gap: "0.5rem"
	},
	itemComment: {
		backgroundColor: "var(--color-grey-background)",
		borderRadius: "var(--border-radius-semi-rounded)",
		color: "var(--color-grey-dark)",
		display: "flex",
		minHeight: "3.5rem",
		padding: "0.7rem 0.85rem",
		whiteSpace: "pre-wrap",
		width: "100%",
		position: "relative",
		wordBreak: "break-word",
		"&:after": {
			left: "-3%",
			width: "0",
			content: '""',
			display: "block",
			zIndex: "-1",
			position: "absolute",
			borderColor: "transparent var(--color-grey-background) transparent transparent",
			borderStyle: "solid",
			borderWidth: "0px 2rem 1rem 0",
			transform: "skew(50deg)",
			transformOrigin: "top left",
			borderRadius: "15% 0 0 0 / 25%"
		}
	},
	itemCommentActions: {
		alignItems: "center",
		display: "flex",
		gap: "0.5rem",
		maxHeight: "100%"
	},
	itemCommentContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "0.5rem",
		marginLeft: "1rem",
		width: "calc(100% - 1rem)"
	},
	itemCommentInput: {
		overflow: "auto",
		"&$itemCommentInput$itemCommentInput": {
			backgroundColor: "var(--color-white)",
			borderRadius: "var(--border-radius-semi-rounded)"
		}
	},
	itemCommentPostButton: {
		width: "fit-content"
	},
	itemContainer: {
		display: "flex",
		gap: "1rem",
		width: "100%",
		minHeight: "6rem",
		borderRadius: "var(--border-radius-semi-rounded)"
	},
	itemMetadata: {
		whiteSpace: "pre-wrap",
		color: "var(--color-grey-disabled)"
	},
	itemPrefix: {
		display: "flex",
		flexDirection: "column",
		gap: "0.75rem"
	},
	itemTitle: {
		"& > *:first-child": {
			maxWidth: "80%"
		},
		gap: "0.5rem",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	title: {
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem"
	},
	approvalIcon: {
		display: "flex",
		justifyContent: "center"
	},
	separator: {
		display: "flex",
		flex: "1 1 auto",
		justifyContent: "center",
		minHeight: "40%"
	},
	line: {
		height: "100%"
	},
	itemUserAvatar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "2.75rem",
		width: "2.75rem"
	},
	entitleLogo: {
		height: "2.3rem",
		width: "2.3rem"
	},
	integrationLogo: {
		objectFit: "contain"
	}
});
