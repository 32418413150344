import { Map } from "immutable";
import constate from "constate";
import { useCallback, useState } from "react";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { getIntegrationResource } from "api/integrationResources";

const useAccessReportResources = () => {
	const [integrationResources, setIntegrationResources] = useState(Map<string, IntegrationResourceModel>());

	const fetchIntegrationResource = useCallback(async (id: string): Promise<IntegrationResourceModel | undefined> => {
		if (!id) return undefined;
		const integrationResource = await getIntegrationResource(id);
		if (integrationResource) setIntegrationResources(current => current.set(id, integrationResource));
		return integrationResource;
	}, []);

	return {
		state: { integrationResources },
		actions: {
			fetchIntegrationResource
		}
	};
};

export const [AccessReportResourcesProvider, useAccessReportResourcesContext] = constate(useAccessReportResources);
