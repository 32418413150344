import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	table: {
		display: "block",
		border: "1px solid var(--surface-block-background-color)",
		backgroundColor: "var(--surface-block-background-color)",
		boxSizing: "border-box"
	},
	title: {
		display: "flex",
		justifyContent: "space-between"
	}
});
