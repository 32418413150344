import { useEffect, useState, useMemo } from "react";
import { useAgentTokensContext } from "context/agentTokensContext";

export const useAgentTokens = () => {
	const {
		state: { agentTokens },
		actions: { loadAgentTokens }
	} = useAgentTokensContext();
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		(async () => {
			if (!agentTokens && !loading) {
				setLoading(true);
				await loadAgentTokens();
				setLoading(false);
			}
		})();
	}, [agentTokens, loadAgentTokens, loading]);

	const tokensList = useMemo(() => agentTokens?.toList(), [agentTokens]);
	return tokensList;
};
