import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	userName: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "1rem",
		background: "var(--color-white)"
	},
	saveButton: {
		width: "fit-content"
	}
});
