import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	sourceIconContainer: {
		alignItems: "center",
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "flex"
	},
	sourceIcon: {
		fontSize: "var(--icon-medium-size)",
		height: "var(--icon-medium-size)",
		width: "var(--icon-medium-size)",
		minWidth: "1em",
		padding: "0.125rem",
		fill: "var(--text-color)"
	}
});
