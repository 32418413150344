import { Map } from "immutable";
import { AccessReviewResourceModel } from "models/AccessReviewResourceModel";
import constate from "constate";
import { useCallback, useState } from "react";
import { getAccessReviewResource as apiGetAccessReviewResource } from "api/accessReviewResources";
import { useLoadingState } from "hooks/useLoadingState";

const useAccessReviewResources = () => {
	const [accessReviewResources, setAccessReviewResources] = useState(Map<string, AccessReviewResourceModel>());
	const { isLoading: isGetLoading, withLoader: withGetLoader } = useLoadingState();

	const getAccessReviewResource = useCallback(
		async (id: string): Promise<AccessReviewResourceModel | null> => {
			if (!id) return null;

			const accessReviewResource = await withGetLoader(apiGetAccessReviewResource(id));
			setAccessReviewResources(current => current.set(id, accessReviewResource));
			return accessReviewResource;
		},
		[withGetLoader]
	);

	const updateResource = useCallback((id: string, resource: AccessReviewResourceModel) => {
		setAccessReviewResources(current => current.set(id, resource));
	}, []);

	return {
		state: { accessReviewResources, isGetLoading },
		actions: {
			getAccessReviewResource,
			updateResource
		}
	};
};

export const [AccessReviewResourcesProvider, useAccessReviewResourcesContext] = constate(useAccessReviewResources);
