import { List, Record } from "immutable";
import { IntegrationResourceRoleModel } from "./IntegrationResourceRoleModel";

const defaults = {
	displayName: "",
	id: "",
	email: "",
	integrationId: "",
	integrationResourceRoles: null as List<IntegrationResourceRoleModel> | null,
	isUsed: false,
	lastUsed: null as Date | null,
	userIds: null as List<string> | null
};

export class IntegrationActorModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): IntegrationActorModel {
		const { displayName, id, integrationId, integrationResourceRoles, isUsed, lastUsed, userIds, email } =
			data as typeof defaults;
		return new IntegrationActorModel({
			displayName,
			id,
			email,
			integrationId,
			integrationResourceRoles: integrationResourceRoles
				? List(integrationResourceRoles.map(IntegrationResourceRoleModel.fromServerData))
				: null,
			isUsed,
			lastUsed: lastUsed ? new Date(lastUsed) : null,
			userIds: userIds ? List(userIds) : null
		});
	}
}
