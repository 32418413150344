import { useCallback, useEffect, useRef, useState } from "react";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";

type TFetchingState = "Initial" | "ShouldRetry" | "Loading" | "Loaded" | "Error";

interface IFetchingStateOptions {
	allowRetry: boolean;
}

export const useFetchedState = <TFetchParams extends unknown[], TFetchResult>(
	fetchingMethod: (...args: TFetchParams) => Promise<TFetchResult>,
	options: Partial<IFetchingStateOptions> = {
		allowRetry: true
	}
) => {
	const allowRetry = options.allowRetry ?? true;

	const [data, setData] = useState<TFetchResult | null>(null);
	const fetchingStateRef = useRef<TFetchingState>("Initial");
	const [fetchingState, setFetchingState] = useState<TFetchingState>("Initial");
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const [previousArgs, setPreviousArgs] = useState<TFetchParams>();

	const loadData = useCallback(
		async (...args: TFetchParams) => {
			setPreviousArgs(args);
			if (!(fetchingStateRef.current === "Initial" || fetchingStateRef.current === "ShouldRetry")) return;
			try {
				fetchingStateRef.current = "Loading";
				setFetchingState(fetchingStateRef.current);
				const newData = await fetchingMethod(...args);
				setData(newData);
				fetchingStateRef.current = "Loaded";
				setFetchingState(fetchingStateRef.current);
			} catch (err) {
				fetchingStateRef.current = "Error";
				const retryLoadData = async () => {
					fetchingStateRef.current = "ShouldRetry";
					setFetchingState(fetchingStateRef.current);
					await loadData(...args);
				};
				openGlobalErrorModal(err as Error, allowRetry ? retryLoadData : undefined);
			}
		},
		[allowRetry, fetchingMethod, openGlobalErrorModal]
	);

	const forceLoadData = useCallback(
		async (...args: TFetchParams) => {
			setPreviousArgs(args);
			if (fetchingStateRef.current === "Loading") return;
			try {
				fetchingStateRef.current = "Loading";
				setFetchingState(fetchingStateRef.current);
				const newData = await fetchingMethod(...args);
				setData(newData);
				fetchingStateRef.current = "Loaded";
				setFetchingState(fetchingStateRef.current);
			} catch (err) {
				fetchingStateRef.current = "Error";
				setFetchingState(fetchingStateRef.current);
				const retryLoadData = async () => forceLoadData(...args);
				openGlobalErrorModal(err as Error, allowRetry ? retryLoadData : undefined);
			}
		},
		[allowRetry, fetchingMethod, openGlobalErrorModal]
	);

	const retryAction = useCallback(async () => {
		fetchingStateRef.current = "ShouldRetry";
		setFetchingState(fetchingStateRef.current);

		await loadData(...((previousArgs || []) as TFetchParams));
	}, [loadData, previousArgs]);

	useEffect(() => {
		fetchingStateRef.current = "Initial";
		setFetchingState(fetchingStateRef.current);
	}, [fetchingMethod]);

	const isLoading = fetchingStateRef.current === "Loading";

	return { data, setData, loadData, forceLoadData, fetchingState, isLoading, retryAction };
};
