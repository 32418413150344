import React from "react";
import { CompanyModel } from "models/CompanyModel";
import { useCompanyContext } from "context/companyContext";
import { OnCallIntegration } from "./components/OnCallIntegration";
import type { TOnCallType } from "models/OnCallIntegrationScheduleModel";

const integrations: TOnCallType[] = ["Opsgenie", "PagerDuty"];

export const OnCallIntegrations: FC<{ company: CompanyModel }> = ({ company }) => {
	const {
		actions: { integrateWithOnCallService, removeOnCallIntegration }
	} = useCompanyContext();

	return (
		<>
			{integrations.map(integration => (
				<OnCallIntegration
					key={integration}
					integration={integration}
					afterIntegrate={integrateWithOnCallService}
					disconnect={removeOnCallIntegration}
					integratedToOnCalls={company.integratedToOnCalls}
				/>
			))}
		</>
	);
};
