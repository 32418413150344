import { useCallback, useEffect, useState } from "react";
import {
	getIntegrationResource,
	TFullIntegrationResourceModel,
	addResourcePermission as apiAddResourcePermission,
	deleteResourcePermission as apiDeleteResourcePermission
} from "api/integrationResources";
import { useOpenGlobalErrorModal } from "./useGlobalError";
import { useLoadingState } from "./useLoadingState";

export const useIntegrationResource = (integrationResourceId: string, full = false, withDeleted = false) => {
	const { withLoader, isLoading } = useLoadingState();
	const { withLoader: actionWithLoader, isLoading: actionIsLoading } = useLoadingState();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const [resource, setResource] = useState<TFullIntegrationResourceModel | null>();

	const loadResource = useCallback(async () => {
		if (!isLoading && integrationResourceId) {
			try {
				const fetchedResource = await withLoader(getIntegrationResource(integrationResourceId, full, withDeleted));
				setResource(fetchedResource);
			} catch (e) {
				setResource(null);
				openGlobalErrorModal(e as Error);
			}
		}
	}, [full, integrationResourceId, isLoading, openGlobalErrorModal, withDeleted, withLoader]);

	const addResourcePermission = useCallback(
		async (roleId: string) => {
			const updatedResource = await actionWithLoader(apiAddResourcePermission(integrationResourceId, roleId));
			setResource(updatedResource);
		},
		[actionWithLoader, integrationResourceId]
	);

	const deleteResourcePermission = useCallback(
		async (roleId: string) => {
			const updatedResource = await actionWithLoader(apiDeleteResourcePermission(integrationResourceId, roleId));
			setResource(updatedResource);
		},
		[actionWithLoader, integrationResourceId]
	);

	useEffect(() => {
		if (resource === undefined || (resource && resource.id !== integrationResourceId)) {
			loadResource();
		}
	}, [integrationResourceId, loadResource, resource]);

	return { resource, loadResource, isLoading, addResourcePermission, actionIsLoading, deleteResourcePermission };
};
