import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PageTemplate } from "components/templates/PageTemplate";
import { LoadingOverlay } from "components/ui/LoadingOverlay";
import { useOnMount } from "hooks/useOnMount";
import { useStyles } from "./styles";
import { NewTicketForm } from "./components/NewTicketForm";
import { NewTicketFormModals } from "./components/NewTicketFormModals";
import { NewTicketProvider, useNewTicketPageContext } from "./newTicketContext";

const NewTicketPageBody: FC = ({ className }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		state: { isOriginalTicketLoading, hasIntegrations },
		actions: { initFromOriginalTicket }
	} = useNewTicketPageContext();

	useOnMount(() => {
		initFromOriginalTicket();
	});

	return (
		<PageTemplate className={classNames(classes.newTicketPage, className)}>
			<LoadingOverlay isOpen={isOriginalTicketLoading || !hasIntegrations} />
			<NewTicketFormModals />
			<PageTemplate.Title>{t("pages.newTicket.title")}</PageTemplate.Title>
			<PageTemplate.Content>{hasIntegrations && <NewTicketForm />}</PageTemplate.Content>
		</PageTemplate>
	);
};

export const NewTicketPage: FC = ({ className }) => {
	return (
		<NewTicketProvider>
			<NewTicketPageBody className={className} />
		</NewTicketProvider>
	);
};
