import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	entitlements: {
		verticalAlign: "middle"
	},
	link: {
		color: "var(--typography-link-font-color)"
	},
	ticketsCell: {
		display: "flex",
		gap: "0.5rem",
		flexWrap: "wrap"
	},
	smallIntegrationImage: {
		height: "1em",
		width: "1em"
	},
	spinner: {
		margin: "auto"
	},
	pageSelect: {
		flexShrink: "0",
		marginTop: "0.3rem"
	},
	table: {
		height: "1px",
		flex: "1",
		padding: "0 1rem",
		margin: "0 -1rem",
		overflowY: "auto"
	},
	tableContainer: {
		display: "flex",
		flexDirection: "column",
		height: "1px",
		flex: 1
	},
	userEntitlementsContainer: {
		display: "flex",
		flexDirection: "column",
		flex: "1"
	},
	header: {
		flexShrink: "0"
	},
	actions: {
		display: "flex",
		justifyContent: "center"
	},
	listContainer: {
		flex: 1,
		height: "100%"
	}
});
