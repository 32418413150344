import React, { useCallback, useMemo } from "react";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useWorkflowEditorContext } from "context/workflowEditorContext";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { OperatorBlock } from "../../OperatorBlock";
import { AccessRequestEditable } from "../AccessRequest";
import { useStyles } from "./styles";

export const AlgorithmRuleEditable = ({ index }: { index: number }) => {
	const {
		state: { rulesAndFlows },
		actions: { removeRuleFlow, changeRuleOrder, getRuleFlowOrShowError }
	} = useWorkflowEditorContext();
	const removeRule = useCallback(() => removeRuleFlow(index), [index, removeRuleFlow]);
	const classes = useStyles();

	const moveUp = useCallback(() => {
		changeRuleOrder(index, -1);
	}, [index, changeRuleOrder]);

	const moveDown = useCallback(() => {
		changeRuleOrder(index, 1);
	}, [index, changeRuleOrder]);

	const currentRule = useMemo(() => getRuleFlowOrShowError(index)?.[0], [getRuleFlowOrShowError, index]);

	if (!currentRule) return null;

	return (
		<>
			<div className={classes.iconsContainer}>
				<ChevronDownIcon size="medium" onClick={moveDown} disabled={index === rulesAndFlows.size - 1} />
				<ChevronUpIcon size="medium" onClick={moveUp} disabled={index === 0} />
				<CloseIcon size="medium" onClick={rulesAndFlows.size > 1 ? removeRule : undefined} />
			</div>
			<OperatorBlock operator="if" />
			<AccessRequestEditable index={index} />
		</>
	);
};
