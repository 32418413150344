import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	roleLabel: {
		display: "flex",
		flexDirection: "column"
	},
	roleContainer: {
		width: "100%",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden"
	},
	roleTitle: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden"
	}
});
