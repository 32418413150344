import { List, Record } from "immutable";
import { AccessReviewReportModel } from "./AccessReviewReportModel";
import { AccessReviewTemplateModel } from "./AccessReviewTemplateModel";

export interface IAccessReviewPermissionsStatistics {
	permissionsStatus: {
		pending: number;
		approved: number;
		denied: number;
		flagged: number;
	};
}

interface IAccessReviewStatistics extends IAccessReviewPermissionsStatistics {
	reportsStatus: {
		pending: number;
		inProgress: number;
		done: number;
	};
}

const defaults = {
	createdAt: new Date(0),
	creatorId: "",
	description: "",
	id: "",
	name: "",
	permissionCount: null as number | null,
	reports: null as List<AccessReviewReportModel> | null,
	reportsCount: null as number | null,
	statistics: null as IAccessReviewStatistics | null,
	status: "" as "pendingCreation" | "created" | "active" | "done",
	template: null as AccessReviewTemplateModel | null,
	templateId: "",
	immediateRevoke: false
};

export class AccessReviewModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): AccessReviewModel {
		const {
			createdAt,
			creatorId,
			description,
			id,
			name,
			permissionCount,
			reports,
			reportsCount,
			statistics,
			status,
			template,
			templateId,
			immediateRevoke
		} = data as typeof defaults;
		return new AccessReviewModel({
			createdAt: new Date(createdAt),
			creatorId,
			description,
			id,
			name,
			permissionCount,
			reports: reports ? List(reports.map(AccessReviewReportModel.fromServerData)) : null,
			reportsCount: reportsCount,
			statistics,
			status,
			template,
			templateId,
			immediateRevoke
		});
	}

	public get reportsSize(): number {
		return this.reportsCount || this.reports?.size || 0;
	}

	public get permissionsSize(): number {
		if (typeof this.permissionCount === "number") return this.permissionCount;
		if (this.statistics) {
			return Object.values(this.statistics.permissionsStatus).reduce((a, b) => a + b, 0);
		}
		return 0;
	}
}
