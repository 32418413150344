import React from "react";
import classNames from "classnames";
import { ChevronLeftIcon } from "components/ui/Icons/ChevronLeftIcon";
import { ChevronRightIcon } from "components/ui/Icons/ChevronRightIcon";
import { IconButton } from "components/ui/IconButton";
import { useStyles } from "./styles";
import type { IReportItem } from "models/AccessReviewReportModel";

interface IAction {
	disabled?: boolean;
	onClick: () => void;
	tooltip?: string;
}

export interface IAccessReviewReportSubjectActionsProps {
	next: IAction;
	previous: IAction;
	sortedReportSubjects?: IReportItem[];
}

export const AccessReviewReportSubjectActions: FC<IAccessReviewReportSubjectActionsProps> = ({
	className,
	id,
	innerRef,
	next,
	previous,
	sortedReportSubjects
}) => {
	const classes = useStyles();
	if (!sortedReportSubjects || !sortedReportSubjects.length) {
		return null;
	}
	return (
		<div className={classNames(classes.actions, className)} id={id} ref={innerRef}>
			<IconButton
				variant="primary"
				size="medium"
				disabled={typeof previous.disabled === "boolean" ? previous.disabled : false}
				onClick={previous.onClick}
				tooltip={previous.tooltip}>
				<ChevronLeftIcon className={classes.lineHeightButton} />
			</IconButton>
			<IconButton
				variant="primary"
				size="medium"
				disabled={typeof next.disabled === "boolean" ? next.disabled : false}
				onClick={next.onClick}
				tooltip={next.tooltip}>
				<ChevronRightIcon className={classes.lineHeightButton} />
			</IconButton>
		</div>
	);
};
