export async function ignoreAbortError<T>(promise: Promise<T>, handleResult: (result: T) => void) {
	try {
		const result = await promise;
		handleResult(result);
	} catch (e) {
		if (!(e instanceof Error) || e.message !== "Request aborted") {
			throw e;
		}
	}
}
