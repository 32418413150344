import { Block } from "components/ui/Block";
import { LoadingDots } from "components/ui/LoadingDots";
import { Title } from "components/ui/Title";
import { UserModel } from "models/UserModel";
import React from "react";
import { useTranslation } from "react-i18next";
import { EntitlementsTable } from "./EntitlementsTable";
import { useStyles } from "./styles";

export const UserEntitlements: FC<{ user?: UserModel }> = ({ user }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Block className={classes.userEntitlementsContainer}>
			<Title className={classes.header} variant="h3" noBorder>
				{t("pages.user.entitlements.title")}
			</Title>
			{user ? <EntitlementsTable user={user} /> : <LoadingDots center />}
		</Block>
	);
};
