import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { createIntegrationResource, TIntegrationResourceCreateBody } from "api/integrationResources";
import { Modal } from "components/ui/Modal";
import { IconPrefix } from "components/ui/IconPrefix";
import { ResourceForm } from "../ResourceForm";
import { useStyles } from "./styles";

interface IProps {
	integrationId: string;
	isOpen?: boolean;
	onClose: () => void;
	onUpdate?: (resource: IntegrationResourceModel) => void;
}

export const ResourceFormModal: FC<IProps> = ({ integrationId, onUpdate, onClose, isOpen = false }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const resetFormRef = useRef<(() => void) | undefined>();

	const onSubmit = useCallback(
		async (resourceToUpdate: TIntegrationResourceCreateBody) => {
			const updatedResource = await createIntegrationResource(resourceToUpdate);
			onUpdate && onUpdate(updatedResource);
			resetFormRef.current && resetFormRef.current();
			onClose();
		},
		[onClose, onUpdate]
	);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			content={
				<div className={classes.content}>
					<IconPrefix size="large" semibold Icon={AddIcon} content={t("pages.integration.createResource")} />
					<ResourceForm integrationId={integrationId} onSubmit={onSubmit} resetFormRef={resetFormRef} />
				</div>
			}
		/>
	);
};
