import { useEffect, useMemo, useRef } from "react";

export default function useMountState() {
	const isMountedRef = useRef(true);

	useEffect(() => {
		isMountedRef.current = true;

		return () => {
			isMountedRef.current = false;
		};
	});

	const res = useMemo(
		() => ({
			get isMounted() {
				return isMountedRef.current;
			},
			ifMounted(callback: () => void) {
				if (isMountedRef.current) callback();
			}
		}),
		[]
	);

	return res;
}
