import { createUseStyles } from "react-jss";

export const TOP_NAV_HEIGHT_PX = 60;

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		height: TOP_NAV_HEIGHT_PX + "px",
		padding: "0 32px 0 28px",
		backgroundColor: "var(--nav-background-color)"
	},
	userContainer: {
		display: "flex",
		maxWidth: "400px",
		alignItems: "center"
	},
	entitleLogo: {
		height: "24px"
	},
	userActionsModal: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		padding: "0",
		zIndex: 9999,
		borderRadius: "6px",
		backgroundColor: "var(--color-white)",
		boxShadow: "0px 8px 7px 7px rgba(0, 0, 0, 0.20)"
	},
	userIcon: {
		"&$userIcon": {
			maxWidth: "20px",
			maxHeight: "20px"
		}
	}
});
