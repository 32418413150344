import React, { SyntheticEvent } from "react";
import classNames from "classnames";
import useShouldMount from "hooks/useShouldMount";
import { useStyles } from "./styles";

const DEFAULT_UNMOUNT_DELAY = 1000 * 1.5;

export const Overlay: FC<OverlayProps> = ({
	isOpen,
	forceMount = false,
	unmountDelay = DEFAULT_UNMOUNT_DELAY,
	className,
	children,
	onMouseDown
}) => {
	const [shouldMount, shouldOpen] = useShouldMount(isOpen, unmountDelay);
	const classes = useStyles();

	if (!forceMount && !shouldMount) return null;

	const overlayNode = (
		<div className={classNames(classes.overlay, !shouldOpen && "hide", className)} onMouseDown={onMouseDown}>
			<div className={classes.content}>{children}</div>
		</div>
	);

	return overlayNode;
};

type OverlayProps = {
	isOpen: boolean;
	forceMount?: boolean;
	unmountDelay?: number;
	onMouseDown?: (event: SyntheticEvent) => void;
};
