import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import {
	getIntegrationCheckboxState,
	useSubordinatePermissionsReviewContext
} from "context/subordinatePermissionReviewContext";
import { CommonAccordion } from "components/common/CommonAccordion";
import { useIntegrations } from "hooks/useIntegrations";
import { SubordinatePermissionsTable } from "../SubordinatePermissionsTable";
import { IntegrationTitle, PermissionsListHeader } from "./helperComponents";
import { useStyles } from "./styles";
import type { IntegrationModel } from "models/IntegrationModel";

interface IProps {
	readonly?: boolean;
	immediateRevoke: boolean;
}

export const SubordinatePermissionsList: FC<IProps> = ({ className, id, innerRef, readonly, immediateRevoke }) => {
	const integrations = useIntegrations(true);
	const classes = useStyles();

	const {
		state: { groupedPermissions, selectedAmount, totalAmount },
		actions: { setSelected, setSelectedComment }
	} = useSubordinatePermissionsReviewContext();

	const subordinateIntegrations = useMemo(
		() =>
			(
				integrations &&
				groupedPermissions &&
				groupedPermissions
					.keySeq()
					.map(integrationId => integrations.get(integrationId))
					.filter(Boolean)
			)?.toArray() || [],
		[groupedPermissions, integrations]
	);

	const setApproved = useCallback(() => setSelected("approve"), [setSelected]);
	const setDenied = useCallback(() => setSelected("deny"), [setSelected]);
	const setFlagged = useCallback(() => setSelected("flag"), [setSelected]);

	if (!groupedPermissions) return null;
	return (
		<div className={classNames(classes.listContainer, className)} id={id} ref={innerRef}>
			{!readonly && (
				<PermissionsListHeader
					selectedAmount={selectedAmount}
					setApproved={setApproved}
					setComment={setSelectedComment}
					setDenied={setDenied}
					setFlagged={setFlagged}
					totalAmount={totalAmount}
					immediateRevoke={immediateRevoke}
				/>
			)}
			<div className="list">
				{subordinateIntegrations.length > 0 &&
					groupedPermissions &&
					subordinateIntegrations
						.filter(Boolean)
						.map(integration => (
							<IntegrationPermissions
								integration={integration!}
								key={integration?.id}
								readonly={readonly}
								immediateRevoke={immediateRevoke}
							/>
						))}
			</div>
		</div>
	);
};

interface IIntegrationPermissionsProps {
	integration: IntegrationModel;
	readonly?: boolean;
	immediateRevoke: boolean;
}

const IntegrationPermissions: FC<IIntegrationPermissionsProps> = ({ integration, readonly, immediateRevoke }) => {
	const classes = useStyles();

	const {
		state: { checkboxesState, groupedPermissions },
		actions: {
			getIsSelected,
			handleIntegrationCheckboxChange,
			handlePermissionCheckboxChange,
			removePermissionCheckbox
		}
	} = useSubordinatePermissionsReviewContext();

	const currentState: "unchecked" | "checked" | "indeterminate" = useMemo(
		() => getIntegrationCheckboxState(checkboxesState, integration),
		[checkboxesState, integration]
	);

	const onIntegrationChange = useCallback(
		(checked: boolean) => handleIntegrationCheckboxChange(integration.id, checked),
		[handleIntegrationCheckboxChange, integration.id]
	);

	const getIsPermissionSelected = useCallback(
		(permissionId: string) => {
			return getIsSelected(integration.id, permissionId);
		},
		[getIsSelected, integration.id]
	);

	const handleSingleCheckboxChange = useCallback(
		(permissionId: string, checked: boolean) => {
			handlePermissionCheckboxChange(integration.id, permissionId, checked);
		},
		[handlePermissionCheckboxChange, integration.id]
	);

	const showCheckbox = useMemo(
		() =>
			groupedPermissions?.get(integration.id)?.some(permission => !(permission.status === "denied" && immediateRevoke)),
		[groupedPermissions, immediateRevoke, integration.id]
	);

	return (
		<div className={classes.accordion}>
			<CommonAccordion
				title={
					<IntegrationTitle
						checkboxOnChange={onIntegrationChange}
						checkboxState={currentState}
						integration={integration}
						readonly={readonly || !showCheckbox}
					/>
				}>
				<SubordinatePermissionsTable
					changeCheckbox={handleSingleCheckboxChange}
					getIsSelected={getIsPermissionSelected}
					permissions={groupedPermissions?.get(integration.id)}
					readonly={readonly}
					immediateRevoke={immediateRevoke}
					integrationId={integration.id}
					removeCheckbox={removePermissionCheckbox}
				/>
			</CommonAccordion>
		</div>
	);
};
