import { useEffect } from "react";
import { useTasksContext } from "context/tasksContext";

export const useTasks = () => {
	const {
		state: { tasks },
		actions: { loadTasks, reloadTasks }
	} = useTasksContext();

	useEffect(() => {
		loadTasks();
	}, [loadTasks]);

	return { tasks, loadTasks, reloadTasks };
};
