import React, { useCallback, useMemo, useState } from "react";
import { Form } from "components/ui/Form";
import { List } from "immutable";
import { useTabState } from "../../hooks/useTabState";
import { RolesInput } from "./components/RolesInput";
import { IntegrationsInput } from "./components/IntegrationsInput";
import { ResourcesInput } from "./components/ResourcesInput";
import { ResourceTypesInput } from "./components/ResourceTypesInput";
import type { TFullRole, TRoleOption } from "../../types";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";

export const NewTicketByResourceForm: FC = () => {
	const { receiverUser, selectedItems, setSelectedItems, getIsItemConflicted } = useTabState();

	const [selectedIntegrations, setSelectedIntegrations] = useState<IntegrationModel[] | null>([]);
	const selectedIntegrationIds = useMemo(() => selectedIntegrations?.map(({ id }) => id) || [], [selectedIntegrations]);

	const [selectedResourceTypes, setSelectedResourceTypes] = useState<string[] | null>([]);

	const [selectedIntegrationResources, setSelectedIntegrationResources] = useState<IntegrationResourceModel[] | null>(
		[]
	);
	const selectedIntegrationResourceIds = useMemo(
		() => selectedIntegrationResources?.map(({ id }) => id) || [],
		[selectedIntegrationResources]
	);

	const setSelectedRoles = useCallback(
		(roles: TFullRole[] | null) => {
			setSelectedItems(List(roles?.map(role => ({ type: "role", value: role }) as TRoleOption) ?? []));
		},
		[setSelectedItems]
	);

	const selectedRoles = useMemo(() => selectedItems.map(({ value }) => value as TFullRole).toArray(), [selectedItems]);

	const isItemConflicted = useMemo(() => getIsItemConflicted(), [getIsItemConflicted]);

	const isRoleConflicted = useCallback(
		(role: TFullRole) => {
			return isItemConflicted({ type: "role", value: role });
		},
		[isItemConflicted]
	);

	if (!receiverUser) return <></>;

	return (
		<>
			<Form.Field>
				<IntegrationsInput
					setIntegrations={setSelectedIntegrations}
					integrations={selectedIntegrations}
					userId={receiverUser.id}
				/>
			</Form.Field>
			<Form.Field>
				<ResourceTypesInput
					setResourceTypes={setSelectedResourceTypes}
					resourceTypes={selectedResourceTypes}
					userId={receiverUser.id}
					integrationIds={selectedIntegrationIds}
				/>
			</Form.Field>
			<Form.Field>
				<ResourcesInput
					setResources={setSelectedIntegrationResources}
					resources={selectedIntegrationResources}
					selectedIntegrationIds={selectedIntegrationIds}
					selectedResourceTypes={selectedResourceTypes}
					userId={receiverUser.id}
				/>
			</Form.Field>
			<Form.Field>
				<RolesInput
					isRoleConflicted={isRoleConflicted}
					selectedResourceIds={selectedIntegrationResourceIds}
					setSelectedRoles={setSelectedRoles}
					selectedRoles={selectedRoles}
					userId={receiverUser.id}
				/>
			</Form.Field>
		</>
	);
};
