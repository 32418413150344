import React from "react";
import { useTranslation } from "react-i18next";
import { Section } from "components/ui/Section";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { UserCard } from "components/common/UserCard";
import { EditButton } from "../EditButton";
import { useStyles } from "./styles";

export const OnBehalfSection: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		state: { currentUser, receiverUser }
	} = useNewRequestFormContext();

	if (!receiverUser || currentUser?.id === receiverUser.id) return null;
	return (
		<Section className={className} title={t("pages.newRequest.summaryStep.onBehalfSection.title")} innerRef={innerRef}>
			<div className={classes.container}>
				<UserCard user={receiverUser} />
				<EditButton stepIndex={0} />
			</div>
		</Section>
	);
};
