import React from "react";
import classNames from "classnames";
import { useOverlaysContext } from "context/overlaysContext";
import { useStyles } from "./styles";

export const SystemOverlays: FC = ({ className, children }) => {
	const classes = useStyles();
	const {
		actions: { setSystemOverlays }
	} = useOverlaysContext();

	return (
		<div
			className={classNames(classes.overlays, className)}
			ref={ref => {
				if (ref) {
					setSystemOverlays(ref);
				}
			}}>
			{children}
		</div>
	);
};
