import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/ui/Modal";
import { Button } from "components/ui/Button";
import { TitleBody } from "components/ui/TitleBody";
import { useStyles } from "./styles";
import { CreatorFilter } from "./components/CreatorFilter";
import { ReceiverFilter } from "./components/ReceiverFilter";
import { StatusFilter } from "./components/StatusFilter";
import { DurationFilter } from "./components/DurationFilter";
import { CreatedAtFilter } from "./components/CreatedAtFilter";
import { TargetFilter } from "./components/TargetFilter";
import type { TSearchTicketsParams } from "api/tickets";

export interface IFilterProps {
	onFiltersChange: React.Dispatch<React.SetStateAction<TSearchTicketsParams>>;
	ticketFilters: TSearchTicketsParams;
}

type TValue<T> = T | T[] | null | undefined;
export function getArrayValue<T>(value: TValue<T>): string[];
export function getArrayValue<T>(
	value: TValue<T>,
	toLabel: (value: T) => string
): {
	value: T;
	label: string;
}[];
export function getArrayValue<T>(value: TValue<T>, toLabel?: (value: T) => string) {
	if (!value) return [];
	if (Array.isArray(value))
		return toLabel ? value.map(element => ({ value: element, label: toLabel(element) })) : value;
	return [toLabel ? { value, label: toLabel(value) } : value];
}

const TicketFilters: FC<IFilterProps> = ({ onFiltersChange, ticketFilters }) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<CreatorFilter onFiltersChange={onFiltersChange} ticketFilters={ticketFilters} />
			<ReceiverFilter onFiltersChange={onFiltersChange} ticketFilters={ticketFilters} />
			<StatusFilter onFiltersChange={onFiltersChange} ticketFilters={ticketFilters} />
			<DurationFilter onFiltersChange={onFiltersChange} ticketFilters={ticketFilters} />
			<CreatedAtFilter onFiltersChange={onFiltersChange} ticketFilters={ticketFilters} />
			<TargetFilter onFiltersChange={onFiltersChange} ticketFilters={ticketFilters} />
		</div>
	);
};

export const TicketFiltersModal: FC<IFilterProps & { isOpen: boolean; onClose: () => void }> = ({
	isOpen,
	onClose,
	onFiltersChange,
	ticketFilters
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			actions={
				<Button variant="secondary" size="medium" onClick={onClose}>
					{t("buttons.close")}
				</Button>
			}
			content={
				<div className={classes.modalContent}>
					<TitleBody size="large" title={t("common.ticketFilters.title")} />
					<TicketFilters onFiltersChange={onFiltersChange} ticketFilters={ticketFilters} />
				</div>
			}
		/>
	);
};
