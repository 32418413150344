import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageTemplate } from "components/templates/PageTemplate";
import { LoadingOverlay } from "components/ui/LoadingOverlay";
import useErrorModalState from "hooks/useErrorModalState";
import { ErrorModal } from "components/ui/ErrorModal";
import { BinaryToggle, TBinaryToggleOptions } from "components/ui/BinaryToggle";
import {
	SortTableProvider,
	useSortTableContext,
	useRespondedTicketsTableContext,
	RespondedTicketsTableProvider
} from "context/sortingTableContext";
import { DESC } from "hooks/usePagination";
import { RequestDetails } from "components/common/RequestDetails";
import { PendingRequestsList } from "./components/PendingRequestsList";
import { useStyles } from "./styles";
import { RecentlyRespondedList } from "./components/RecentlyRespondedList";
import { PENDING_PER_PAGE, usePendingTickets } from "./usePendingTickets";
import { RESPONDED_TO_PER_PAGE, useRespondedToTickets } from "./useRespondedToTickets";
import type { TicketModel } from "models/TicketModel";

const PendingRequestsPageContent: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const {
		state: { sortOrder: respondedSortOrder, sortFields: respondedSortFields }
	} = useRespondedTicketsTableContext();
	const {
		state: { sortOrder: pendingSortOrder, sortFields: pendingSortFields }
	} = useSortTableContext();

	const {
		errorModalSetError,
		errorModalClose: closeErrorModal,
		errorModalError,
		errorModalIsOpen
	} = useErrorModalState();

	const {
		pendingTickets,
		respondToTicket,
		totalPendingTickets,
		getPage: pendingGetPage
	} = usePendingTickets(pendingSortOrder, pendingSortFields);

	const {
		getPage: respondedToGetPage,
		totalResults: totalRespondedToTickets,
		tickets: respondedToTickets,
		clearData
	} = useRespondedToTickets(respondedSortOrder, respondedSortFields);

	const onRespondToTicket = useCallback(
		async (ticketId: string, response: boolean): Promise<TicketModel> => {
			const newTicket = await respondToTicket(ticketId, response);
			clearData();
			return newTicket;
		},
		[respondToTicket, clearData]
	);

	const [displayState, setDisplayState] = useState<"pending" | "respondedTo">("pending");

	const toggleDisplayState = useCallback(() => {
		setDisplayState(current => (current === "pending" ? "respondedTo" : "pending"));
	}, []);

	const title = t(`pages.pendingRequests.${displayState}Title`);

	const toggleOptions: TBinaryToggleOptions<string> = useMemo(
		() => [
			{ label: t("pages.pendingRequests.pending"), value: "pending" },
			{ label: t("pages.pendingRequests.respondedTo"), value: "respondedTo" }
		],
		[t]
	);

	return (
		<PageTemplate className={className}>
			<RequestDetails />
			<LoadingOverlay isOpen={pendingTickets === null} />
			<ErrorModal error={errorModalError} isOpen={errorModalIsOpen} onClose={closeErrorModal} />
			<PageTemplate.Title className={classes.titleContainer}>
				{title}
				<BinaryToggle
					even
					fontSize="small"
					onChange={toggleDisplayState}
					options={toggleOptions}
					value={displayState}
				/>
			</PageTemplate.Title>
			<PageTemplate.Content className={classes.pendingRequestsPage}>
				{displayState === "pending" ? (
					pendingTickets && (
						<PendingRequestsList
							tickets={pendingTickets}
							totalTickets={totalPendingTickets}
							getPage={pendingGetPage as (page: number) => Promise<void>}
							perPage={PENDING_PER_PAGE}
							respondToTicket={onRespondToTicket}
							errorModalSetError={errorModalSetError}
						/>
					)
				) : (
					<RecentlyRespondedList
						getPage={respondedToGetPage as (page: number) => Promise<void>}
						perPage={RESPONDED_TO_PER_PAGE}
						tickets={respondedToTickets}
						totalTickets={totalRespondedToTickets}
					/>
				)}
			</PageTemplate.Content>
		</PageTemplate>
	);
};

export const PendingRequestsPage: FC = props => (
	<RespondedTicketsTableProvider defaultSortField="ticketNumber" defaultSortOrder={DESC} secondaryField="ticketNumber">
		<SortTableProvider defaultSortField="ticketNumber" defaultSortOrder={DESC} secondaryField="ticketNumber">
			<PendingRequestsPageContent {...props} />
		</SortTableProvider>
	</RespondedTicketsTableProvider>
);
