import React, { useEffect } from "react";
import classNames from "classnames";
import { PageTemplate } from "components/templates/PageTemplate";
import { useTranslation } from "react-i18next";
import { useTasks } from "hooks/useTasks";
import { RequestDetails } from "components/common/RequestDetails";
import { TasksStatusColumns } from "./components/TaskStatusColumns";
import { useStyles } from "./styles";

const INTERVAL_MILLISECONDS = 1000 * 3 * 60;

export const TasksPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const { reloadTasks } = useTasks();
	const classes = useStyles();

	useEffect(() => {
		const intervalId = setInterval(reloadTasks, INTERVAL_MILLISECONDS);
		return () => clearInterval(intervalId);
	}, [reloadTasks]);

	return (
		<PageTemplate className={classNames(classes.tasksPage, className)}>
			<RequestDetails />
			<PageTemplate.Title className={classes.titleContainer}>
				<span>{t("pages.tasks.title")}</span>
			</PageTemplate.Title>
			<PageTemplate.Content className={classes.pageContent}>
				<TasksStatusColumns />
			</PageTemplate.Content>
		</PageTemplate>
	);
};
