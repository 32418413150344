import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IModalBaseProps, Modal } from "components/ui/Modal";
import { Button } from "components/ui/Button";
import { useLoadingState } from "hooks/useLoadingState";
import { TitleBody } from "components/ui/TitleBody";
import classNames from "classnames";
import { useStyles } from "./styles";

interface IProps extends IModalBaseProps {
	isOpen: boolean;
	title?: string;
	content?: React.ReactNode;
	closeLabel?: string;
	onClose: () => void;
	actionLabel?: string;
	onAction: () => Promise<unknown> | unknown;
	onCancel?: () => void;
	className?: string;
	contentClassName?: string;
}

export const AreYouSureModal: FC<IProps> = ({
	isOpen,
	title,
	content,
	closeLabel,
	actionLabel,
	parentElement,
	onClose,
	onCancel,
	onAction: propsOnAction,
	className,
	contentClassName
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const resolvedTitle = title ?? t("common.areYouSure.title");
	const resolvedContent = content ?? t("common.areYouSure.content");
	const resolvedCloseLabel = closeLabel ?? t("buttons.dismiss");
	const resolvedActionLabel = actionLabel ?? t("buttons.delete");

	const { isLoading, withLoader } = useLoadingState();
	const onAction = useCallback(async () => {
		await withLoader(propsOnAction());
	}, [propsOnAction, withLoader]);

	return (
		<Modal
			isOpen={isOpen}
			className={classNames(classes.modal, className)}
			onClose={onClose}
			content={
				<TitleBody
					size="large"
					className={classNames(classes.content, contentClassName)}
					title={resolvedTitle}
					body={resolvedContent}
				/>
			}
			parentElement={parentElement}
			actions={
				<>
					<Button variant="secondary" size="medium" onClick={onCancel ?? onClose}>
						{resolvedCloseLabel}
					</Button>
					<Button size="medium" onClick={onAction} loading={isLoading}>
						{resolvedActionLabel}
					</Button>
				</>
			}
		/>
	);
};
