import { useCallback, useMemo } from "react";
import intersection from "lodash/intersection";
import { TApprovalStatus } from "components/common/ApprovalStatusChip";
import { TRespondersInGroup } from "components/common/TicketsListComponents";
import { TicketModel } from "models/TicketModel";
import { TicketApprovalEntityModel } from "models/TicketApprovalEntityModel";

export const useTicketApprovers = (ticket: TicketModel | null) => {
	const { approverUserIds, declinerUserIds, approverWebhookIds, declinerWebhookIds } = useMemo(() => {
		const defaultApprovers = {
			approverUserIds: [] as string[],
			declinerUserIds: [] as string[],
			approverWebhookIds: [] as string[],
			declinerWebhookIds: [] as string[]
		};
		return (
			ticket?.ticketApprovalResponses?.reduce((acc, response) => {
				if (response?.userId) {
					if (response.type === "approve" || response.type === "adminApprove") {
						acc.approverUserIds.push(response.userId);
					} else if (response.type === "decline" || response.type === "adminDecline") {
						acc.declinerUserIds.push(response.userId);
					}
				}
				if (response?.webhookId) {
					if (response.type === "approve") {
						acc.approverWebhookIds.push(response.webhookId);
					} else if (response.type === "decline") {
						acc.declinerWebhookIds.push(response.webhookId);
					}
				}
				return acc;
			}, defaultApprovers) || defaultApprovers
		);
	}, [ticket]);

	const getApprovalStatus = useCallback(
		(entity: TicketApprovalEntityModel): TApprovalStatus => {
			if (entity.type === "Webhook" && !entity.approverIds.size) {
				if (approverWebhookIds.includes(entity.identifier!)) return "approved";
				if (declinerWebhookIds.includes(entity.identifier!)) return "rejected";
				return "pending";
			}
			if (entity.approvers.every(({ userIds }) => intersection(approverUserIds, userIds.toArray()).length > 0))
				return "approved";

			if (intersection(entity.approverIds.toArray(), declinerUserIds).length > 0) return "rejected";

			return "pending";
		},
		[approverUserIds, approverWebhookIds, declinerUserIds, declinerWebhookIds]
	);

	const getRespondersInGroup = useCallback(
		(userIds: string[]): TRespondersInGroup => {
			const approversIntersection = intersection(userIds, approverUserIds);
			if (approversIntersection.length > 0) return [approversIntersection, "approved"];
			const declinersIntersection = intersection(userIds, declinerUserIds);
			if (declinersIntersection.length > 0) return [declinersIntersection, "rejected"];
			return [[], "pending"];
		},
		[approverUserIds, declinerUserIds]
	);

	return { getApprovalStatus, getRespondersInGroup };
};
