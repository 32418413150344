import { useAccessReviewSubordinatesContext } from "context/accessReviewSubordinatesContext";
import { useCallback, useEffect } from "react";
import type { TAccessReviewSubordinateModel } from "models/AccessReviewSubordinateModel";

export const useAccessReviewSubordinate = (id: string | undefined) => {
	const {
		state: { accessReviewSubordinates, isGetLoading },
		actions: {
			getAccessReviewSubordinate: contextGetAccessReviewSubordinate,
			updateSubordinate: contextUpdateSubordinate
		}
	} = useAccessReviewSubordinatesContext();

	const getAccessReviewSubordinate = useCallback(async () => {
		if (!id) {
			return undefined;
		}
		return contextGetAccessReviewSubordinate(id);
	}, [contextGetAccessReviewSubordinate, id]);

	const updateSubordinate = useCallback(
		(fields: Partial<TAccessReviewSubordinateModel>) => {
			if (!id) {
				return undefined;
			}
			return accessReviewSubordinates?.has(id)
				? contextUpdateSubordinate(id, accessReviewSubordinates.get(id)!.merge(fields))
				: undefined;
		},
		[accessReviewSubordinates, contextUpdateSubordinate, id]
	);

	useEffect(() => {
		if (id && !accessReviewSubordinates?.has(id)) getAccessReviewSubordinate();
	}, [accessReviewSubordinates, getAccessReviewSubordinate, id]);

	return {
		accessReviewSubordinate: id ? accessReviewSubordinates.get(id) : undefined,
		getAccessReviewSubordinate,
		isGetLoading,
		updateSubordinate
	};
};
