import {
	bulkApproveAccessReviewPermissions as apiBulkApproveAccessReviewPermissions,
	bulkDenyAccessReviewPermissions as apiBulkDenyAccessReviewPermissions,
	bulkFlagAccessReviewPermissions as apiBulkFlagAccessReviewPermissions,
	TAccessReviewPermissionType
} from "api/accessReviewPermissions";
import { useCallback } from "react";
import { useLoadingState } from "./useLoadingState";

export const useAccessReviewPermissions = () => {
	const { isLoading, withLoader } = useLoadingState();

	const bulkApproveAccessReviewPermissions = useCallback(
		async (permissionIds: string[], type: TAccessReviewPermissionType) => {
			return withLoader(apiBulkApproveAccessReviewPermissions(permissionIds, type));
		},
		[withLoader]
	);

	const bulkDenyAccessReviewPermissions = useCallback(
		async (permissionIds: string[], type: TAccessReviewPermissionType) => {
			return withLoader(apiBulkDenyAccessReviewPermissions(permissionIds, type));
		},
		[withLoader]
	);

	const bulkFlagAccessReviewPermissions = useCallback(
		async (permissionIds: string[], type: TAccessReviewPermissionType) => {
			return withLoader(apiBulkFlagAccessReviewPermissions(permissionIds, type));
		},
		[withLoader]
	);

	return {
		bulkApproveAccessReviewPermissions,
		bulkDenyAccessReviewPermissions,
		bulkFlagAccessReviewPermissions,
		isLoading
	};
};
