import { Map } from "immutable";
import { AccessReviewReportModel } from "models/AccessReviewReportModel";
import constate from "constate";
import { useCallback, useState } from "react";
import { getAccessReviewReport as apiGetAccessReviewReport } from "api/accessReviewsReport";

const useAccessReviewReports = () => {
	const [accessReviewReports, setAccessReviewReports] = useState(Map<string, AccessReviewReportModel>());

	const getAccessReviewReport = useCallback(async (id: string): Promise<AccessReviewReportModel | null> => {
		if (!id) return null;
		const accessReviewReport = await apiGetAccessReviewReport(id);
		setAccessReviewReports(current => current.set(id, accessReviewReport));
		return accessReviewReport;
	}, []);

	return {
		state: { accessReviewReports },
		actions: {
			getAccessReviewReport
		}
	};
};

export const [AccessReviewReportsProvider, useAccessReviewReportsContext] = constate(useAccessReviewReports);
