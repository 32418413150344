import { getTicket } from "api/tickets";
import { TicketModel } from "models/TicketModel";
import { useCallback, useEffect, useState } from "react";

export const useTicket = (ticketId: string) => {
	const [ticket, setTicket] = useState<TicketModel | null>(null);
	const [loading, setLoading] = useState(false);
	const [notFound, setNotFound] = useState(false);

	const loadTicket = useCallback(async () => {
		if (!ticketId) {
			return null;
		}
		setLoading(true);
		setTicket(null);
		try {
			const newTicket = await getTicket(ticketId);
			if (newTicket) {
				setTicket(newTicket);
				setNotFound(false);
			} else {
				setNotFound(true);
				setTicket(null);
			}
			return newTicket;
		} catch {
			setNotFound(true);
			setTicket(null);
		} finally {
			setLoading(false);
		}
		return null;
	}, [ticketId]);

	useEffect(() => {
		loadTicket();
	}, [loadTicket]);

	return {
		ticket,
		loading,
		notFound,
		reloadTicket: loadTicket,
		setTicket
	};
};
