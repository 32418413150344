import { useEffect } from "react";
import { useIntegrationsContext } from "context/integrationsContext";

export const useIntegrations = (withDeleted = false) => {
	const {
		state: { integrations, existingIntegrations },
		actions: { loadIntegrations }
	} = useIntegrationsContext();

	useEffect(() => {
		loadIntegrations();
	}, [loadIntegrations]);

	return withDeleted ? integrations : existingIntegrations;
};
