import React from "react";
import { useTranslation } from "react-i18next";
import { AreYouSureModal } from "../AreYouSureModal";

interface IProps {
	onAction: () => Promise<void>;
	onClose: () => void;
	isOpen: boolean;
}

export const ImmediateRevokeModal: FC<IProps> = ({ onAction, onClose, isOpen }) => {
	const { t } = useTranslation();

	return (
		<AreYouSureModal
			onClose={onClose}
			isOpen={isOpen}
			onAction={onAction}
			actionLabel={t("buttons.revoke")}
			title={t("common.immediateRevokeModal.title")}
			content={t("common.immediateRevokeModal.content")}
		/>
	);
};
