import React from "react";
import classNames from "classnames";

import { useStyles } from "./styles";

export const Block: FC = ({ className, children }) => {
	const classes = useStyles();

	return <div className={classNames(classes.block, className)}>{children}</div>;
};

export const HelpBlock: FC = ({ className, children }) => {
	const classes = useStyles();

	return <div className={classNames(classes.block, classes.helpBlock, className)}>{children}</div>;
};
