import { useCallback, useEffect, useState } from "react";
import { List } from "immutable";
import { getAccessReviewReports } from "api/accessReviewsReport";
import { AccessReviewReportModel } from "models/AccessReviewReportModel";

export const useUserAccessReviewReports = () => {
	const [reports, setReports] = useState<List<AccessReviewReportModel>>();
	const [loading, setLoading] = useState(false);

	const getUserReports = useCallback(async () => {
		setLoading(true);
		const newReports = await getAccessReviewReports();
		setReports(newReports);
		setLoading(false);
	}, []);

	useEffect(() => {
		!reports && getUserReports();
	}, [getUserReports, reports]);

	return {
		getUserReports,
		isLoading: loading,
		reports
	};
};
