import { useEffect } from "react";
import { useApplicationsContext } from "context/applicationsContext";

export const useApplications = () => {
	const {
		state: { applications },
		actions: { loadApplications }
	} = useApplicationsContext();

	useEffect(() => {
		loadApplications();
	}, [loadApplications]);

	return applications;
};
