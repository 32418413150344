import { useState } from "react";
import constate from "constate";

const useBeta = () => {
	const [beta, setBeta] = useState<boolean>(false);

	return { state: { beta }, actions: { setBeta } };
};

export const [BetaProvider, BetaContext, useIsBeta] = constate(
	useBeta,
	value => value,
	value => value.state.beta
);
