import { List, Record } from "immutable";
import { AccessReviewPermissionCommentModel } from "./AccessReviewPermissionCommentModel";
import { AccessReviewResourceModel } from "./AccessReviewResourceModel";
import { AccessReviewResourcePermissionModel } from "./AccessReviewResourcePermissionModel";
import { AccessReviewSubordinateModel } from "./AccessReviewSubordinateModel";
import { AccessReviewSubordinatePermissionModel } from "./AccessReviewSubordinatePermissionModel";
import { IntegrationActorPermissionModel } from "./IntegrationActorPermissionModel";

export type TAccessReviewPermissionStatus = "pending" | "approved" | "denied" | "flagged";

const defaults = {
	actorName: "",
	calculatedStatus: undefined as TAccessReviewPermissionStatus | undefined,
	comments: null as List<AccessReviewPermissionCommentModel> | null,
	id: "",
	integrationActorPermission: null as IntegrationActorPermissionModel | null,
	integrationActorPermissionId: "",
	resourceName: "",
	resourcePermissions: null as List<AccessReviewResourcePermissionModel> | null,
	resources: null as List<AccessReviewResourceModel> | null,
	roleName: "",
	subordinatePermissions: null as List<AccessReviewSubordinatePermissionModel> | null,
	subordinates: null as List<AccessReviewSubordinateModel> | null,
	userIds: null as List<string> | null
};

export type TAccessReviewPermissionModel = typeof defaults;

export class AccessReviewPermissionModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): AccessReviewPermissionModel {
		const {
			actorName,
			calculatedStatus,
			comments,
			id,
			integrationActorPermission,
			integrationActorPermissionId,
			resourceName,
			resourcePermissions,
			resources,
			roleName,
			subordinatePermissions,
			subordinates,
			userIds
		} = data as typeof defaults;
		return new AccessReviewPermissionModel({
			actorName,
			id,
			calculatedStatus,
			comments: comments ? List(comments.map(AccessReviewPermissionCommentModel.fromServerData)) : null,
			integrationActorPermission: integrationActorPermission
				? IntegrationActorPermissionModel.fromServerData(integrationActorPermission)
				: null,
			integrationActorPermissionId,
			resourceName,
			resourcePermissions: resourcePermissions
				? List(resourcePermissions.map(AccessReviewResourcePermissionModel.fromServerData))
				: null,
			resources: resources ? List(resources.map(AccessReviewResourceModel.fromServerData)) : null,
			roleName,
			subordinatePermissions: subordinatePermissions
				? List(subordinatePermissions.map(AccessReviewSubordinatePermissionModel.fromServerData))
				: null,
			subordinates: subordinates ? List(subordinates.map(AccessReviewSubordinateModel.fromServerData)) : null,
			userIds: userIds ? List(userIds) : null
		});
	}
}
