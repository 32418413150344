import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	extraOptionsButton: {
		alignItems: "center",
		display: "inline-flex",
		flexDirection: "row",
		flexWrap: "wrap",
		fontSize: "1rem",
		gap: "0.5rem",
		height: "2rem",
		justifyContent: "center",
		overflowWrap: "inherit",
		width: "2rem"
	}
});
