import { List, Record } from "immutable";
import { BundleItemModel } from "models/BundleItemModel";
import { BundleModel } from "models/BundleModel";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { BundleData, BundleItem } from "./useBundleFormState";

const EmptyBundleItem = Record<BundleItem>({
	integration: null,
	resource: null,
	role: null,
	id: undefined
});

export function bundleModelToBundleData(
	bundle: BundleModel | undefined,
	integrationMapping: Immutable.Map<string, IntegrationModel> | null
) {
	if (!bundle) return bundle;
	return {
		bundleItems: List(
			bundle.bundleItems?.map(item =>
				EmptyBundleItem({
					integration: item.integrationResourceRole.integrationResource?.integrationId
						? integrationMapping?.get(item.integrationResourceRole.integrationResource?.integrationId) || null
						: null,
					resource: item.integrationResourceRole.integrationResource,
					role: item.integrationResourceRole,
					id: item.id
				})
			)
		),
		allowedDurations: bundle.allowedDurations,
		approvalAlgorithm: bundle.approvalAlgorithm,
		category: bundle.category,
		name: bundle.name,
		id: bundle.id,
		description: bundle.description,
		tags: bundle.tags
	};
}

export function bundleDataToBundleModel(bundle: BundleData, overrideDurations: boolean) {
	return new BundleModel({
		...bundle,
		category: bundle.category,
		allowedDurations: overrideDurations ? bundle.allowedDurations : null,
		bundleItems: bundle.bundleItems?.map(
			resource =>
				new BundleItemModel({
					id: resource.get("id"),
					integrationResourceRole: (resource.get("role") || undefined) as IntegrationResourceRoleModel | undefined
				})
		)
	});
}
