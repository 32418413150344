import { Button } from "components/ui/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

interface IProps {
	handleButtonClick: () => void;
	onFileSelection: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
	fileInputRef: React.RefObject<HTMLInputElement>;
}

export const ImageReplaceButton: FC<IProps> = ({ handleButtonClick, onFileSelection, fileInputRef }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div>
			<Button variant="secondary" size="small" onClick={handleButtonClick}>
				{t("buttons.replaceImage")}
			</Button>
			<input
				type="file"
				accept=".png, .jpeg, .gif, .bmp, .webp"
				ref={fileInputRef}
				className={classes.hiddenInput}
				onChange={onFileSelection}
			/>
		</div>
	);
};
