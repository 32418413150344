import { useEffect, useMemo } from "react";
import { useAdminsContext } from "context/adminsContext";

export const useAdmins = () => {
	const {
		state: { admins },
		actions: { loadAdmins }
	} = useAdminsContext();

	useEffect(() => {
		loadAdmins();
	}, [loadAdmins]);

	const adminsList = useMemo(() => admins?.toList(), [admins]);

	return adminsList;
};
