import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	entityTagsContainer: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "0.5rem"
	},
	entityTagChip: {
		"&:hover": {
			backgroundColor: "var(--color-white)"
		}
	},
	entityLimitChip: {
		"&:hover": {
			backgroundColor: "var(--color-grey-background)",
			cursor: "pointer"
		}
	},
	entityName: {
		marginBottom: "0.4rem"
	},
	entityDescription: {
		fontSize: "0.85rem",
		marginBottom: "0.6rem",
		"&:not(&$disabled)": {
			color: "var(--color-grey-dark)"
		}
	},
	collapsedChipsValues: {
		maxWidth: "20rem",
		overflow: "auto"
	},
	disabled: {}
});
