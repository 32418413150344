import React, { useMemo } from "react";
import classNames from "classnames";
import { Checkbox } from "components/ui/Checkbox";
import { IconPrefix } from "components/ui/IconPrefix";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { Tooltip } from "components/ui/Tooltip";
import { useTranslation } from "react-i18next";
import { TitleBody } from "components/ui/TitleBody";
import { RoleBarTag } from "../RoleBarTag";
import { useStyles } from "./styles";

type TWithCheckbox = {
	checkbox: true;
	checkboxValue: boolean;
	onToggle: () => void;
};

type TWithoutCheckbox = {
	checkbox?: false;
	checkboxValue?: never;
	onToggle?: never;
};

type TWithBundle = {
	name: string;
	description?: string | null;
	tags?: string[] | null;
};

type TWithoutBundle = {
	name?: never;
	description?: never;
	tags?: never;
};

type TCheckboxPartProps = TWithCheckbox | TWithoutCheckbox;

type TBundlePartProps = TWithBundle | TWithoutBundle;

type TProps = TCheckboxPartProps & TBundlePartProps;

const CheckboxPart: React.FC<TCheckboxPartProps> = props => {
	if (!props.checkbox) {
		return null;
	}
	return <Checkbox noMargin selected={props.checkboxValue} onClick={props.onToggle} />;
};

const BundlePart: React.FC<TBundlePartProps> = props => {
	const classes = useStyles();
	const { t } = useTranslation();

	const content = useMemo(() => {
		if (!props.name) {
			return null;
		}
		return (
			<>
				<Tooltip
					content={
						<div className={classes.tooltipContainer}>
							<TitleBody size="small" title={t("common.roleBar.roleBarBundle.bundleName")} body={props.name} />
							{props.description ? (
								<TitleBody
									size="small"
									title={t("common.roleBar.roleBarBundle.bundleDescription")}
									body={props.description}
								/>
							) : null}
						</div>
					}>
					<IconPrefix Icon={BundleIcon} content={props.name} />
				</Tooltip>
				{props.tags ? <RoleBarTag tags={props.tags} /> : null}
			</>
		);
	}, [classes.tooltipContainer, props.description, props.name, props.tags, t]);

	return <div className={classes.bundlePart}>{content}</div>;
};

export const RoleBarBundle: FC<TProps> = ({ className, innerRef, ...restProps }) => {
	const classes = useStyles();

	return (
		<div
			className={classNames(classes.container, { [classes.withCheckbox]: !!restProps.checkbox }, className)}
			ref={innerRef}>
			<CheckboxPart {...restProps} />
			<BundlePart {...restProps} />
		</div>
	);
};
