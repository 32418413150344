import { AccessReviewAuditLogModel } from "./AccessReviewAuditLogModel";
import { ApprovalAlgorithmAuditLogModel } from "./ApprovalAlgorithmAuditLogModel";
import { BundleAuditLogModel } from "./BundleAuditLogModel";
import { IntegrationAuditLogModel } from "./IntegrationAuditLogModel";
import { IntegrationResourceAuditLogModel } from "./IntegrationResourceAuditLogModel";
import { IntegrationResourceRoleAuditLogModel } from "./IntegrationResourceRoleAuditLogModel";
import { TicketAuditLogModel } from "./TicketAuditLogModel";
import { CompanyAuditLogModel } from "./CompanyAuditLogModel";
import { PolicyAuditLogModel } from "./PolicyAuditLogModel";
import { ApprovalFlowsWebhookAuditLogModel } from "./ApprovalFlowsWebhookAuditLogModel";

export type TAuditLogModel =
	| AccessReviewAuditLogModel
	| ApprovalAlgorithmAuditLogModel
	| BundleAuditLogModel
	| IntegrationAuditLogModel
	| IntegrationResourceAuditLogModel
	| IntegrationResourceRoleAuditLogModel
	| TicketAuditLogModel
	| CompanyAuditLogModel
	| PolicyAuditLogModel
	| ApprovalFlowsWebhookAuditLogModel;

export {
	AccessReviewAuditLogModel,
	ApprovalAlgorithmAuditLogModel,
	BundleAuditLogModel,
	IntegrationAuditLogModel,
	IntegrationResourceAuditLogModel,
	IntegrationResourceRoleAuditLogModel,
	TicketAuditLogModel,
	CompanyAuditLogModel,
	PolicyAuditLogModel,
	ApprovalFlowsWebhookAuditLogModel
};
