import React from "react";
import get from "lodash/get";
import classNames from "classnames";
import { useStyles } from "./styles";

interface IProps {
	inline?: boolean;
	size?: "medium" | "small" | "big" | "extra-small" | number;
}

export const LoadingSpinner: FC<IProps> = ({ className, inline, size = "medium" }) => {
	const classes = useStyles(typeof size === "number" ? { size } : undefined);

	const sizeClassName = get(classes, size) || classes.customSize;

	const result = (
		<div className={classNames(classes.loadingSpinner, className, sizeClassName, { inline })}>
			<svg className={classes.spinner} width="64px" height="64px" viewBox="0 0 66 66">
				<circle className={classes.path} cx="33" cy="33" r="30" />
			</svg>
		</div>
	);

	if (inline) {
		return <> {result} </>;
	}

	return result;
};
