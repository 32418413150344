import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	option: {
		padding: "8px 8px 8px 12px",
		alignSelf: "stretch",
		color: "var(--color-black)",
		"&:hover:not(:active)": {
			backgroundColor: "var(--button-text-background-color-hover)"
		},
		display: "flex",
		gap: "8px",
		cursor: "pointer",
		alignItems: "center"
	},
	prefix: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		width: "1em",
		fontSize: "1.25em"
	}
});
