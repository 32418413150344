import React, { useCallback, useMemo, useState } from "react";
import { List } from "immutable";
import { Form } from "components/ui/Form";
import { useTabState } from "../../hooks/useTabState";
import { BundleCategoriesInput } from "./components/BundleCategoriesInput";
import { BundlesInput } from "./components/BundlesInput";
import type { TBundleOption, TFullBundle } from "../../types";

export const NewTicketByBundleForm: FC = () => {
	const { receiverUser, selectedItems, setSelectedItems, getIsItemConflicted } = useTabState();

	const setSelectedBundles = useCallback(
		(bundles: TFullBundle[] | null) => {
			setSelectedItems(List(bundles?.map(bundle => ({ type: "bundle", value: bundle }) as TBundleOption) ?? []));
		},
		[setSelectedItems]
	);

	const selectedBundles = useMemo(
		() => selectedItems.map(({ value }) => value as TFullBundle).toArray(),
		[selectedItems]
	);

	const isItemConflicted = useMemo(() => getIsItemConflicted(), [getIsItemConflicted]);

	const isBundleConflicted = useCallback(
		(bundle: TFullBundle) => {
			return isItemConflicted({ type: "bundle", value: bundle });
		},
		[isItemConflicted]
	);

	const [selectedCategories, setSelectedCategories] = useState<string[] | null>(null);

	if (!receiverUser) return <></>;

	return (
		<>
			<Form.Field>
				<BundleCategoriesInput
					bundleCategories={selectedCategories}
					setBundleCategories={setSelectedCategories}
					userId={receiverUser.id}
				/>
			</Form.Field>
			<Form.Field>
				<BundlesInput
					isBundleConflicted={isBundleConflicted}
					userId={receiverUser.id}
					bundleCategories={selectedCategories}
					bundles={selectedBundles}
					setBundles={setSelectedBundles}
				/>
			</Form.Field>
		</>
	);
};
