import React, { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { List } from "immutable";
import { DescribedCheckbox } from "components/common/DescribedCheckbox";
import { AllowedDurations } from "components/common/AllowedDurations";
import { TTicketDuration } from "utils/durationsOptions";
import { useStyles } from "./styles";

interface IProps {
	allowedDurations: List<TTicketDuration>;
	setAllowedDurations: Dispatch<SetStateAction<List<TTicketDuration>>>;
	overrideAllowedDurations: boolean;
	setOverrideAllowedDurations: Dispatch<SetStateAction<boolean>>;
	disabled: boolean;
	type: "integration" | "resource" | "role" | "bundle";
}

export const DurationsOverrideSelect: FC<IProps> = ({
	allowedDurations,
	setAllowedDurations,
	overrideAllowedDurations,
	setOverrideAllowedDurations,
	disabled = false,
	type
}) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const toggleOverrideAllowedDuration = useCallback(() => {
		setOverrideAllowedDurations(current => !current);
	}, [setOverrideAllowedDurations]);

	const clickDuration = useCallback(
		(duration: TTicketDuration) =>
			setAllowedDurations(current => {
				const durationIndex = current.findIndex(d => d === duration);
				if (durationIndex > -1) return current.remove(durationIndex);
				return current.push(duration);
			}),
		[setAllowedDurations]
	);

	return (
		<>
			<DescribedCheckbox
				label={t("pages.integration.overrideAllowedDurations.label", { type })}
				onClick={toggleOverrideAllowedDuration}
				className={classes.overrideDurations}
				selected={overrideAllowedDurations}
				description={`${t("pages.integration.overrideAllowedDurations.description", { type })}${
					type !== "bundle" ? ` ${t("pages.integration.overrideAllowedDurations.bundleEffect", { type })}` : ""
				}`}
				disabled={disabled}
			/>
			{overrideAllowedDurations && (
				<AllowedDurations disabled={disabled} onToggle={clickDuration} selectedDurations={allowedDurations} />
			)}
		</>
	);
};
