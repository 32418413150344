import React from "react";
import { useNewTicketModalContext } from "../../newTicketContext";
import { FillMissingActorsModal } from "./FillMissingActorsModal";
import { ReceiverMissingActorsModal } from "./ReceiverMissingActors";
import { SuccessModal } from "./SuccessModal";

export const NewTicketFormModals: FC = () => {
	const {
		state: { isOpen, receiverUser, integrationsWithMissingActors, formResult },
		actions: { onClose, onSubmit }
	} = useNewTicketModalContext();

	return (
		<>
			<SuccessModal isOpen={formResult === "success" && isOpen} onClose={onClose} />

			<FillMissingActorsModal
				isOpen={formResult === "fillMissingActors" && isOpen}
				missingIntegrations={integrationsWithMissingActors}
				onSubmit={onSubmit}
				onClose={onClose}
			/>

			<ReceiverMissingActorsModal
				missingIntegrations={integrationsWithMissingActors}
				receiverUser={receiverUser}
				onClose={onClose}
				isOpen={formResult === "receiverMissingActors" && isOpen}
			/>
		</>
	);
};
