import { getI18nTextIfExists } from "utils/i18n";

export type TErrorId =
	| "accessReview.alreadyActive"
	| "accessReview.finished"
	| "accessReview.noPermissionsToReview"
	| "accessReview.notActive"
	| "adaptersService.badRequest"
	| "adaptersService.connection"
	| "allowedDurations.emptyResources"
	| "authentication.company.notFound"
	| "company.tooManySyncs"
	| "db.failure"
	| "db.failure.connection"
	| "db.failure.validation"
	| "directoryConfiguration.nameTaken"
	| "fetch.accessTokenException"
	| "fetch.forbiddenRequest"
	| "fetch.receivedNonJsonResponse"
	| "fetch.sessionExpired"
	| "fetch.timeOut"
	| "fetch.unexpectedStatusCode"
	| "forwards.notAllowed"
	| "hrIntegration.requestFailed"
	| "hrService.connection"
	| "identityProvider.entityIdAlreadyExists"
	| "integration.adapter.invalidAction"
	| "integration.adapterless.agentToken" // cspell:disable-line
	| "integration.cannotAddConfiguration"
	| "integration.invalidConfiguration"
	| "integration.readonly"
	| "integration.tooManySyncs"
	| "maxAgents.reached"
	| "notificationAdapter.connection"
	| "onCall.failed"
	| "request.badRequest"
	| "request.internalServerError"
	| "request.unauthenticated"
	| "request.unauthorized"
	| "resource.inUse"
	| "resource.mustHaveRoles"
	| "resource.notFound"
	| "role.alreadyExists"
	| "role.approvalAlgorithmNotFound"
	| "role.cannotBeUsedAsPrerequisitePermission"
	| "saml.couldNotGetMetadata"
	| "saml.couldNotReachIdP"
	| "saml.identityProviderNotLoaded"
	| "session.userNotFound"
	| "signIn.google.emailNotVerified"
	| "signIn.google.failedToCreateUser"
	| "signIn.google.missingFields"
	| "signIn.google.notAllowed"
	| "signIn.google.tokenVerificationFailed"
	| "signIn.microsoft.failedToCreateUser"
	| "signIn.microsoft.notAllowed"
	| "signIn.microsoft.tokenVerificationFailed"
	| "signIn.microsoft.userRequestFailed"
	| "signIn.teams.emailNotVerified"
	| "signIn.teams.failedToCreateUser"
	| "signIn.teams.missingFields"
	| "signIn.teams.tokenVerificationFailed"
	| "task.cantCreate"
	| "ticket.Empty"
	| "ticket.cantCancelNonPending"
	| "ticket.cantRedirectTo.receiver"
	| "ticket.cantRedirectTo.yourself"
	| "ticket.noMatchingApprovalAlgorithm"
	| "ticket.notWaitingForApproval"
	| "ticketingIntegration.cantIntegrate"
	| "ticketingIntegration.alreadyExist"
	| "url.blocked"
	| "user.actor.notFound"
	| "user.revokeOwnAdmin"
	| "workflow.approverDoesNotExist"
	| "workflow.invalid"
	| "workflow.nameAlreadyExists";

const filterHasField = (params: { field?: string; message?: string }): params is { field: string } => {
	return params.field !== "company_id" && typeof params.field === "string";
};

const mapDbFailureValidationError = (params: Record<string, unknown>): { fields: string[] } => {
	const validationParams = params as { fields?: { field?: string; message?: string }[] };
	return {
		fields: validationParams?.fields?.filter(filterHasField).map(params => params.field) || []
	};
};

const mapResourceInUseError = (
	params: Record<string, unknown>
): { resourceInUse: string; usedByResources: string; usages: string[] } => {
	const resourceInUseParams = params as { names?: string[]; entityInUse?: string; usedByEntity?: string };
	const resourceInUse =
		getI18nTextIfExists("entities." + resourceInUseParams.entityInUse) || getI18nTextIfExists("entities.entity") || "";
	const usedByResources =
		getI18nTextIfExists("entities.plural." + resourceInUseParams.usedByEntity) ||
		getI18nTextIfExists("entities.plural.entity") ||
		"";
	return {
		resourceInUse,
		usedByResources,
		usages: resourceInUseParams.names || []
	};
};

type TMapPropsFunction = (params: Record<string, unknown>) => Record<string, unknown>;

const mappedErrorIds = new Map<TErrorId, TMapPropsFunction>([
	["db.failure.validation", mapDbFailureValidationError],
	["resource.inUse", mapResourceInUseError]
]);

export const mapErrorParams = (errorId: TErrorId, params: Record<string, unknown>) => {
	const mapFunction = mappedErrorIds.get(errorId);
	if (mapFunction) {
		return mapFunction(params);
	} else {
		return params;
	}
};
