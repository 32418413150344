import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	spinner: {
		margin: "auto"
	},
	bundles: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "wrap",
		gap: "2.0rem"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	}
});
