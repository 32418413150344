import React from "react";

import { useStyles } from "./styles";

interface IProps {
	onClick: () => void;
	text: string;
	Logo: FC;
	baseColor?: string;
}

export const BaseLoginButton: FC<IProps> = ({ onClick, text, Logo, baseColor = "var(--color-white)" }) => {
	const classes = useStyles({ baseColor });

	return (
		<button className={classes.button} onClick={onClick} name="loginButton">
			<div className={classes.logoContainer}>{<Logo className={classes.logo} />}</div>
			<div className={classes.text}>{text}</div>
		</button>
	);
};
