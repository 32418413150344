import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	bold: {
		"&$bold": { fontWeight: "var(--typography-font-weight-bold)" }
	},
	status: {
		"&.prefix > *:first-child": {
			marginRight: "0.55rem"
		},
		alignItems: "center",
		flexDirection: "row"
	},
	content: {
		alignItems: "center",
		display: "inline-flex",
		gap: "0.1rem"
	},
	icon: {
		height: "0.95rem"
	}
});
