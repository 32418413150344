import { REQUEST_BAR_HEIGHT } from "components/common/RequestBar/styles";
import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	animationOverlay: {
		backgroundColor: "transparent",
		borderRadius: "16px",
		height: `${REQUEST_BAR_HEIGHT}px`,
		left: 0,
		opacity: 0,
		pointerEvents: "none",
		position: "absolute",
		textAlign: "center",
		top: 0,
		transition: "opacity 0.5s ease-in-out",
		width: "100%"
	},
	"@keyframes barDisappear": {
		"0%": { height: `${REQUEST_BAR_HEIGHT}px` },
		"80%": {
			height: 0,
			padding: 0
		},
		"100%": {
			height: 0,
			padding: 0
		}
	},
	baseAnimation: {
		animationDelay: "1.5s",
		animationDuration: "0.8s",
		animationFillMode: "forwards",
		animationName: "$barDisappear",
		animationTimingFunction: "ease-in-out"
	},
	approvedAnimation: {
		extend: "baseAnimation",
		"& > $animationOverlay": {
			backgroundColor: "var(--color-green-100)",
			border: "3px solid var(--color-green-400)",
			color: "var(--color-green-400)",
			opacity: 1
		}
	},
	deniedAnimation: {
		extend: "baseAnimation",
		"& > $animationOverlay": {
			backgroundColor: "var(--color-red-200)",
			border: "3px solid var(--color-red-700)",
			color: "var(--color-red-700)",
			opacity: 1
		}
	},
	overlayText: {
		alignItems: "center",
		display: "inline-flex",
		gap: "var(--spacing-x6, 24px)",
		position: "relative",
		top: "50%",
		transform: "translateY(-50%)"
	}
});
