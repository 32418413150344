import merge from "lodash/merge";
import { createUseStyles } from "react-jss";
import { animations } from "./transitions";

const transitionableStyles = {
	transitionable: {
		willChange: "opacity, transform",
		"--animation-duration": "500ms",

		"&:not(.animate).in": {
			visibility: "hidden",
			pointerEvents: "none"
		},

		"&.out": {
			pointerEvents: "none"
		}
	}
};

// using merge and not safe merge because we want the key to contain special characters - "[], ()"
const styles = merge(transitionableStyles, animations);

export const useStyles = createUseStyles(styles);
