import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	changeProfileForm: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		background: "none"
	},
	nameBlock: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem"
	},
	integrationActorsBlock: {
		background: "var(--color-white)",
		display: "flex",
		flexDirection: "column",
		gap: "1rem"
	}
});
