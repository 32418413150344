import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem",
		width: "100%",
		overflow: "hidden"
	},
	text: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		minWidth: "1px",
		width: "100%",
		display: "inline-block"
	},
	image: {
		height: "1rem",
		width: "1rem"
	}
});
