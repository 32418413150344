import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	addButton: {
		display: "none"
	},
	policyRowContainer: {
		minHeight: "6rem"
	},
	policyRowData: {
		display: "flex",
		gap: "2rem"
	},
	policyRowSection: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	policyRowActions: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	policyRowList: {
		padding: "0.5rem",
		gap: "0.5rem",
		maxHeight: "fit-content",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center"
	},
	givesAccessSubSection: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-around",
		gap: "1rem",
		overflow: "auto"
	}
});
