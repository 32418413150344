import { Map } from "immutable";
import { useEffect, useMemo } from "react";
import { useBundlesContext } from "context/bundlesContext";
import { BundleModel } from "models/BundleModel";
import { useOnMount } from "hooks/useOnMount";

export const useBundles = (withDeleted = false, force = false) => {
	const {
		state: { bundles },
		actions: { loadBundles, reloadBundles }
	} = useBundlesContext();

	useEffect(() => {
		loadBundles();
	}, [loadBundles]);

	useOnMount(() => force && reloadBundles());

	const filteredBundles: Map<string, BundleModel> | null = useMemo(() => {
		return withDeleted || !bundles ? bundles : bundles.filter(bundle => !bundle.isDeleted);
	}, [bundles, withDeleted]);

	return filteredBundles;
};
