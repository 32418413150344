import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		cursor: "inherit",
		display: "flex",
		gap: "0.5rem",
		width: "100%",
		alignItems: "center"
	},
	title: {
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		overflow: "hidden",
		justifyContent: "center"
	},
	deleted: {
		color: "var(--color-red)",
		alignSelf: "start",
		height: 1
	}
});
