import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	appImage: {
		display: "inline-block",
		height: "1.5em",
		marginRight: ".1em",
		overflow: "hidden",
		verticalAlign: "middle",
		width: "1.5em"
	},
	approvalAlgorithmContainer: {
		flexGrow: 1,
		overflow: "hidden"
	},
	backButton: {
		color: "inherit",
		cursor: "pointer",
		display: "inline-flex",
		fontSize: "2.5rem",
		marginRight: "1rem",
		textDecoration: "none",
		verticalAlign: "middle"
	},
	block: {
		display: "grid",
		flexGrow: 1,
		gap: "1rem",
		gridTemplateColumns: "45rem 1fr"
	},
	checkboxField: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	container: {
		display: "flex",
		flexGrow: 1,
		gap: "2rem"
	},
	integrationForm: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1
	},
	integrationTitle: {
		alignItems: "center",
		display: "flex",
		justifyContent: "space-between"
	},
	integrationTitleBlock: {
		alignItems: "center",
		display: "inline-flex",
		flexDirection: "row"
	},
	sendRequestButtonContainer: {
		alignItems: "center",
		display: "flex",
		justifyContent: "right",
		paddingTop: "0.5rem"
	},
	spinner: {
		margin: "auto"
	},
	toggleOauthButton: {
		alignSelf: "center"
	},
	userOption: {
		alignItems: "center",
		display: "inline-flex",
		flexDirection: "row",
		gap: "0.5rem"
	}
});
