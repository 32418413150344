import LogRocket from "logrocket";
import { compiledClientConfig } from "config";
import { UserModel } from "models/UserModel";
import { getCurrentVersion } from "utils/version";
import set from "lodash/set";

let logrocketInitialized = false;

const SANITIZED_REQUEST_URLS = ["/v1/adaptersTokens", "/v1/apiTokens"];
const SANITIZED_RESPONSE_FIELDS = ["accessToken"];
const SANITIZED_REQUEST_FIELDS = ["configuration"];

export const logrocketInit = async () => {
	const clientId = compiledClientConfig.logrocketClientId;
	if (clientId) {
		const version = await getCurrentVersion();
		const environment = compiledClientConfig.appEnv;
		LogRocket.init(clientId, {
			release: `${version}_${environment}`,
			network: {
				responseSanitizer: response => {
					delete response.headers.authorization;
					delete response.headers.token;
					if (!response.body) return response;
					const body =
						typeof response.body === "string" ? JSON.parse(response.body) : (response.body as Record<string, unknown>);
					for (const field of Object.keys(body)) {
						if (SANITIZED_RESPONSE_FIELDS.includes(field)) {
							set(body, field, "HIDDEN");
						}
					}
					response.body = JSON.stringify(body);
					return response;
				},
				requestSanitizer: request => {
					delete request.headers.authorization;
					delete request.headers.token;
					if (SANITIZED_REQUEST_URLS.some(url => request.url.includes(url))) {
						return null;
					}
					if (!request.body) return request;
					const body =
						typeof request.body === "string" ? JSON.parse(request.body) : (request.body as Record<string, unknown>);
					for (const field of Object.keys(body)) {
						if (SANITIZED_REQUEST_FIELDS.includes(field)) {
							set(body, field, "HIDDEN");
						}
					}
					request.body = JSON.stringify(body);
					return request;
				}
			}
		});
		logrocketInitialized = true;
	}
};

export const setLogrocketUserScope = (user: UserModel) => {
	if (!logrocketInitialized) return;
	if (user) {
		LogRocket.identify(user.id, {
			companyDomain: user.email.split("@")[1],
			email: user.email,
			name: user.fullName,
			role: user.role
		});
	}
};

export const logrocketLog = (message: string, extra?: Record<string, string | number | boolean>) => {
	if (!logrocketInitialized) return;
	LogRocket.captureMessage(message, {
		extra
	});
};

export const logrocketLogError = (error: Error, extra?: Record<string, string | number | boolean>) => {
	if (!logrocketInitialized) return;
	LogRocket.captureException(error, { extra: { stack: error.stack || new Error().stack || "", ...extra } });
};

export const logrocketGetSessionUrl = (): string | null => {
	if (!logrocketInitialized) return null;
	return LogRocket.sessionURL;
};
