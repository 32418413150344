import { AddIcon } from "components/ui/Icons/AddIcon";
import { Button } from "components/ui/Button";
import { useWorkflowEditorContext } from "context/workflowEditorContext";
import { List } from "immutable";
import { ApprovalFlowRequestModel } from "models/ApprovalFlowRequestModel";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

interface IProps {
	index: number;
}

export const AddFlowStep: FC<IProps> = ({ index }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		actions: { updateFlow, getRuleFlowOrShowError }
	} = useWorkflowEditorContext();
	const ruleFlow = useMemo(() => getRuleFlowOrShowError(index), [getRuleFlowOrShowError, index]);
	const addStep = useCallback(() => {
		const currentFlow = ruleFlow?.[1];
		if (!currentFlow) return;
		const { requests } = currentFlow;
		if (!requests) {
			const lastSortOrder = 0;
			const updatedRequests = List<ApprovalFlowRequestModel>().push(
				new ApprovalFlowRequestModel({ sortOrder: lastSortOrder + 1, approvers: List(), operator: "or" })
			);
			updateFlow(index, {
				requests: updatedRequests
			});
		} else {
			const lastSortOrder = requests.sortBy(r => r.sortOrder).last()?.sortOrder || 0;
			const updatedRequests = requests.push(
				new ApprovalFlowRequestModel({ sortOrder: lastSortOrder + 1, approvers: List(), operator: "or" })
			);
			updateFlow(index, {
				requests: updatedRequests
			});
		}
	}, [index, ruleFlow, updateFlow]);
	return (
		<Button variant="secondary" className={classes.button} size="medium" onClick={addStep}>
			<AddIcon />
			{t("flow.addStep")}
		</Button>
	);
};
