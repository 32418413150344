import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useStepperContext } from "components/common/Stepper/stepperContext";
import { useNewRequestFormContext } from "../../newRequestFormContext";
import { RequestReasonInputs } from "../RequestReasonInputs";

export const RequestReasonStep: FC<{ onExit: () => void }> = ({ className, innerRef, onExit }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.requestReasonStep" });

	const {
		state: { justification }
	} = useNewRequestFormContext();
	const {
		state: { canContinue },
		actions: { setCanContinue, nextStep, setFooterActionLabel }
	} = useStepperContext();

	useEffect(() => {
		setCanContinue(!!justification);
	}, [justification, setCanContinue]);

	useEffect(() => {
		setFooterActionLabel("next", t("nextLabel"));
		return () => {
			setFooterActionLabel("next", undefined);
		};
	}, [canContinue, nextStep, setFooterActionLabel, t]);

	return (
		<PageTitleContent innerRef={innerRef} className={className} noTransition>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top>
					<PageTitleContent.Header.Actions>
						<IconButton size="large" onClick={onExit}>
							<CloseIcon />
						</IconButton>
					</PageTitleContent.Header.Actions>
				</PageTitleContent.Header.Top>
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title title={t("title")} subtitle={t("subtitle")} />
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body>
				<RequestReasonInputs />
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
