import { useAccessReviewsContext } from "context/accessReviewsContext";
import { useCallback, useEffect } from "react";
import { useLoadingState } from "./useLoadingState";

export const useFullAccessReview = (accessReviewId?: string, force = false) => {
	const {
		state: { fullAccessReviews },
		actions: { loadAccessReview: contextLoadAccessReview }
	} = useAccessReviewsContext();

	const isLoadingState = useLoadingState();

	const loadAccessReview = useCallback(async () => {
		if (accessReviewId) {
			await isLoadingState.withLoader(contextLoadAccessReview(accessReviewId, force));
		}
	}, [isLoadingState, contextLoadAccessReview, accessReviewId, force]);

	useEffect(() => {
		if ((accessReviewId && !fullAccessReviews.get(accessReviewId)) || force) {
			loadAccessReview();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessReviewId]);

	return {
		accessReview: accessReviewId ? fullAccessReviews.get(accessReviewId) : null,
		isLoading: isLoadingState.isLoading
	};
};
