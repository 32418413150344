import { Tooltip } from "components/ui/Tooltip";
import { UserModel } from "models/UserModel";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IBaseProps {
	componentProps?: Record<string, unknown>;
	email?: never;
	name?: never;
	prefixIcon?: JSX.Element;
	user?: never;
	isDeleted?: never;
	variant?: "Chip" | "TextWithDirectoryIcon" | "Text" | "DirectoryGroup";
}

interface IUserProps extends Omit<IBaseProps, "user"> {
	user?: UserModel;
}

interface IUserNameProps extends Omit<IBaseProps, "name" | "email" | "isDeleted"> {
	name?: string;
	email?: string;
	isDeleted?: boolean;
}

type TProps = IUserProps | IUserNameProps;

export const EmailTooltip: FC<TProps> = ({ children, email, isDeleted = false, name, user }) => {
	const { t } = useTranslation();

	const info: { name?: string; email?: string; isDeleted?: boolean } = useMemo(() => {
		if (user) {
			return { name: user.fullName, email: user.email, isDeleted: user.isDeleted };
		}
		return { name: name, email: email, isDeleted: isDeleted };
	}, [user, name, email, isDeleted]);

	const componentToWrap = useMemo(() => {
		return !React.isValidElement(children) ? <span>{children}</span> : children;
	}, [children]);

	let content = info?.email || user?.id;
	if (info.isDeleted) {
		content = `${info?.email ? info.email + " " : ""}(${t("entities.deletedEntity", { context: "capital" })})`;
	}
	return <Tooltip content={content}>{componentToWrap}</Tooltip>;
};
