import React, { useCallback, useMemo } from "react";
import random from "lodash/random";
import classNames from "classnames";
import { useLoadingState } from "hooks/useLoadingState";
import { PaginatedVirtualList } from "components/ui/VirtualList";
import { REQUEST_BAR_HEIGHT } from "../RequestBar/styles";
import { useStyles, calculateHeight, GAP_SIZE } from "./styles";
import type { TicketModel } from "models/TicketModel";

type TProps = { shownSize?: number };

export const RequestList: FC<TProps> = ({ className, children, innerRef, shownSize = 3 }) => {
	const classes = useStyles({ shown: shownSize });

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			{children}
		</div>
	);
};

type TVirtualListProps = {
	requestRenderer: (request?: TicketModel) => React.ReactNode;
	requests: (TicketModel | undefined)[];
	fetchPage: (page: number) => Promise<unknown> | void;
	perPage: number;
	totalRequests: number;
	fullHeight?: boolean;
	shownSize?: number;
};

export const VirtualRequestList: FC<TVirtualListProps> = ({
	totalRequests,
	className,
	fetchPage,
	requestRenderer,
	perPage,
	requests,
	shownSize = 3,
	fullHeight = false
}) => {
	const { isLoading, withLoader } = useLoadingState();

	const loadMoreRequests = useCallback(
		async (page: number) => {
			if (isLoading) return;
			await withLoader(fetchPage(page));
		},
		[fetchPage, isLoading, withLoader]
	);

	const visibleSize = useMemo(
		() => (requests.length >= shownSize ? shownSize : requests.length),
		[requests, shownSize]
	);

	const height = useMemo(() => calculateHeight({ shown: visibleSize }), [visibleSize]);
	const getRequestKey = useCallback((request?: TicketModel) => request?.id || `loading-${random(true)}`, []);

	return (
		<PaginatedVirtualList
			className={className}
			fetchPage={loadMoreRequests}
			gapSize={GAP_SIZE}
			getKey={getRequestKey}
			height={fullHeight ? undefined : height}
			itemHeight={REQUEST_BAR_HEIGHT}
			itemRenderer={requestRenderer}
			items={requests}
			perPage={perPage}
			totalItems={totalRequests}
		/>
	);
};
