import React, { ReactNode, useMemo } from "react";
import { List as imuList } from "immutable";
import classNames from "classnames";
import { useStyles } from "./styles";

type TListLike = React.ReactNode[] | imuList<React.ReactNode>;

interface IBaseProps {
	variant?: "ul" | "ol" | "none";
	children?: React.ReactNode;
}

interface IItemsProps extends IBaseProps {
	items: TListLike;
	children?: null;
}

type TProps = IBaseProps | IItemsProps;

const isItemProps = (val: unknown): val is IItemsProps => {
	return !!(val as IItemsProps)?.items && Array.isArray((val as IItemsProps).items);
};

const ItemContainer: FC = ({ children }) => {
	const classes = useStyles();
	return <div className={classes.itemContainer}>{children}</div>;
};

const getMap = ({ variant = "ul", className }: { variant?: string; className: string }) => {
	const mapUnordered = (item: ReactNode, index: number) => (
		<div className={className} key={index}>
			{item && <ItemContainer>{item}</ItemContainer>}
		</div>
	);

	const mapOrdered = (item: ReactNode, index: number) => (
		<div className={className} key={index}>
			<span>{`${index}.`}</span>
			<ItemContainer>{item}</ItemContainer>
		</div>
	);

	const mapNoVariant = (item: ReactNode, index: number) => (
		<div className={className} key={index}>
			<ItemContainer>{item}</ItemContainer>
		</div>
	);

	if (variant === "ul") return mapUnordered;
	if (variant === "ol") return mapOrdered;
	return mapNoVariant;
};

export const List: FC<TProps> = props => {
	const { className, variant, ...rest } = props;
	const classes = useStyles();
	const map = useMemo(() => getMap({ variant, className: classes.item }), [variant, classes]);
	const listItems = useMemo(
		() => (isItemProps(rest) ? rest.items.map(map) : React.Children.map(rest.children, map)),
		[map, rest]
	);
	return <div className={classNames(classes.container, className)}>{listItems}</div>;
};
