import React, { useCallback, useEffect, useRef, useState } from "react";
import { List } from "immutable";
import { useResizeDetector } from "react-resize-detector";
import classNames from "classnames";
import { VariableSizeList, type ListOnItemsRenderedProps, type ListChildComponentProps } from "react-window";
import isEqual from "lodash/isEqual";
import { useStyles } from "./styles";

interface IProps<T> {
	renderRow: (data: T, index: number) => React.ReactNode;
	rowContainerClassName?: string;
	rows: T[];
	fetchMore?: () => Promise<void> | void;
	estimateSize?: number | undefined;
	canFetchMore?: boolean;
	overscan?: number; // The amount of items to load both behind and ahead of the current window range
}

type TGridRowProps<T extends object> = {
	row?: T | undefined;
	style: React.CSSProperties;
	index: number;
	setSize: (index: number, height: number) => void;
	estimateHeight: number;
	rowContainerClassName?: string;
	renderRow: (data: T, index: number) => React.ReactNode;
};

const Row = <T extends object>({
	row,
	renderRow,
	index,
	estimateHeight
}: Pick<TGridRowProps<T>, "row" | "renderRow" | "index" | "estimateHeight">) => {
	if (!row) {
		return <div style={{ height: estimateHeight }}></div>;
	} else {
		return <>{renderRow(row, index)}</>;
	}
};

const RowMemo = React.memo(Row) as typeof Row;

const RowWrapper = <T extends object>({
	row,
	setSize,
	style,
	estimateHeight,
	index,
	rowContainerClassName,
	renderRow
}: TGridRowProps<T>) => {
	const { ref, height } = useResizeDetector({ handleWidth: false });

	useEffect(() => {
		if (height) {
			setSize(index, height);
		}
	}, [height, index, setSize]);

	return (
		<div style={style} className={rowContainerClassName}>
			<div ref={ref} style={{ display: "grid" }}>
				<RowMemo estimateHeight={estimateHeight} index={index} renderRow={renderRow} row={row} />
			</div>
		</div>
	);
};

const RowWrapperMemo = React.memo(RowWrapper, (prev, next) => isEqual(prev, next)) as typeof RowWrapper;

export const VirtualScroll = <T extends object>({
	className,
	renderRow,
	rowContainerClassName,
	rows,
	fetchMore,
	estimateSize = 50,
	canFetchMore,
	overscan = 5
}: TProps<IProps<T>>) => {
	const classes = useStyles();

	const [loading, setLoading] = useState(false);

	const itemCount = canFetchMore ? rows.length + 1 : rows.length;
	const loadMoreItems = useCallback(async () => {
		if (!loading && canFetchMore && fetchMore) {
			setLoading(true);
			await fetchMore();
			setLoading(false);
		}
	}, [canFetchMore, fetchMore, loading]);

	const { ref: containerRef, width: containerWidth, height: containerHeight } = useResizeDetector();

	const listRef = useRef<VariableSizeList>(null);
	const sizeMapping = useRef<List<number>>(List<number>());
	const setSize = useCallback((index: number, size: number) => {
		sizeMapping.current = sizeMapping.current.set(index, size);
		listRef.current?.resetAfterIndex(index);
	}, []);

	const getSize = useCallback(
		(index: number) => (index === itemCount - 1 && canFetchMore ? 0 : sizeMapping.current.get(index) || estimateSize), // last item is a dummy item for loading more so we don't count it for height
		[estimateSize, itemCount, canFetchMore]
	);

	const rowRender = useCallback(
		({ index, style }: ListChildComponentProps<T>) =>
			index === itemCount - 1 && canFetchMore ? null : (
				<RowWrapperMemo
					renderRow={renderRow}
					row={rows.at(index)}
					index={index}
					style={style}
					setSize={setSize}
					estimateHeight={estimateSize}
					rowContainerClassName={rowContainerClassName}
				/>
			),
		[estimateSize, renderRow, rowContainerClassName, rows, setSize, itemCount, canFetchMore]
	);

	const handleItemsRendered: (params: ListOnItemsRenderedProps) => void = useCallback(
		({ overscanStopIndex }) => {
			if (canFetchMore && overscanStopIndex === rows.length) {
				loadMoreItems();
			}
		},
		[canFetchMore, loadMoreItems, rows.length]
	);

	useEffect(() => {
		listRef.current?.resetAfterIndex(0);
	}, [rows]);

	return (
		<div className={classNames(classes.container, className)} ref={containerRef}>
			{containerWidth && containerHeight && (
				<VariableSizeList
					height={containerHeight}
					width={containerWidth}
					ref={listRef}
					itemSize={getSize}
					itemCount={itemCount}
					overscanCount={overscan}
					onItemsRendered={handleItemsRendered}>
					{rowRender}
				</VariableSizeList>
			)}
		</div>
	);
};
