import { multiGetIntegrationResourceRoles } from "api/integrationResourceRoles";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useCallback, useEffect, useState } from "react";
import { useOpenGlobalErrorModal } from "./useGlobalError";
import { useLoadingState } from "./useLoadingState";

export const useIntegrationResourceRoles = (integrationResourceRoleIds: string[], withDeleted = false) => {
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const [roles, setRoles] = useState<IntegrationResourceRoleModel[] | null>(null);
	const { withLoader, isLoading } = useLoadingState();

	const loadRoles = useCallback(async () => {
		await withLoader(
			(async () => {
				try {
					let fetchedRoles: IntegrationResourceRoleModel[] = [];
					if (integrationResourceRoleIds.length) {
						fetchedRoles = await multiGetIntegrationResourceRoles(integrationResourceRoleIds, withDeleted);
					}
					setRoles(fetchedRoles);
				} catch (e) {
					openGlobalErrorModal(e as Error);
					setRoles([]);
				}
			})()
		);
	}, [integrationResourceRoleIds, openGlobalErrorModal, withDeleted, withLoader]);

	useEffect(() => {
		setRoles(null);
	}, [integrationResourceRoleIds]);

	useEffect(() => {
		if (roles === null && !isLoading) {
			loadRoles();
		}
	}, [roles, isLoading, loadRoles]);

	return roles;
};
